import { Box, Button, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/system';
import { useEffect, useState } from 'react';
import { generatePath, Prompt, useHistory, useParams } from 'react-router-dom';
import TechTree from '../../Tools/TechTree';

export const ModsPage = () => {
  const [showTTT, setShowTTT] = useState(false);
  const history = useHistory();
  const theme = useTheme();
  const { pageParam, innerPageParam } = useParams<{
    pageParam: string | undefined;
    innerPageParam: string | undefined;
  }>();

  useEffect(() => {
    if (innerPageParam === 'ttt') setShowTTT(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (pageParam !== 'mods') setShowTTT(false);
  }, [pageParam]);

  return (
    <Fill>
      <Typography
        variant="h3"
        style={{ color: theme.palette.text.primary, padding: theme.spacing(2) }}
      >
        Mods Page
      </Typography>
      <Typography
        style={{ color: theme.palette.text.primary, padding: theme.spacing(2) }}
      >
        This page will be filled with a grid of trending mods. Scrolling down
        will show tools for modders, currently there's the tech tree
        organization tool
      </Typography>
      <Prompt
        when={showTTT}
        message="You have unsaved changes, are you sure you want to leave?"
      />
      <Button
        onClick={() => {
          if (!showTTT) {
            setShowTTT(true);
            history.push({
              pathname: generatePath(`/mods/ttt`, {
                orderId: 'latest',
                orderItemId: 'latest',
              }),
            });
          }
        }}
        style={{ marginLeft: '5%' }}
        variant="outlined"
        color="secondary"
      >
        Open Tech Tree Tool
      </Button>
      <Box style={{ height: '90%' }}>{showTTT && <TechTree />}</Box>
    </Fill>
  );
};

const Fill = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  overflow: 'hidden',
}));

export default ModsPage;
