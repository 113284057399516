import {
  AccountCircle,
  GetApp,
  Menu as MenuIcon,
  More,
  MusicNote,
  MusicOff,
  PlayArrow,
  ShoppingCart,
} from '@mui/icons-material';
import {
  AppBar,
  Badge,
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/system';
import { Dispatch, Fragment, useContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useHistory, useParams } from 'react-router-dom';
import { ReactSoundProps } from 'react-sound';
import { COOKIE_MUTED } from '../contexts/initialLoader.context';
import { UserContext } from '../contexts/user.context';
import { PaymentDialog } from './dialogs/DownloadDialog';
import { ProfileDialog } from './dialogs/ProfileDialog';
import { ScrewsDialog } from './dialogs/ScrewsDialog';
import { SigninDialog } from './dialogs/SigninDialog';
import { NumberText } from './NumberText';
import { Sidebar, SidebarPages } from './Sidebar';

export enum TopbarPages {
  PROFILE = 'profile',
  DOWNLOAD = 'download',
  DOWNLOAD_REDIRECT = 'download-redirect',
  SIGNIN = 'signin',
  REGISTER = 'register',
  SCREWS = 'screws',
}

export const Topbar = (props: {
  songPlaying: ReactSoundProps['playStatus'];
  setSongPlaying: Dispatch<ReactSoundProps['playStatus']>;
}) => {
  const history = useHistory();
  const theme = useTheme();
  const { pageParam } = useParams<{
    pageParam: string | undefined;
    innerPageParam: string | undefined;
  }>();
  const { songPlaying, setSongPlaying } = props;
  const [sidebarOpen, setSidebarOpen] = useState(false);

  useEffect(() => {
    if (Object.values(SidebarPages).includes(pageParam as any))
      setSidebarOpen(true);
  }, [pageParam]);

  const [moreMenuAnchor, setMoreMenuAnchor] = useState<null | HTMLElement>(
    null
  );
  const [accountMenuAnchor, setAccountMenuAnchor] =
    useState<null | HTMLElement>(null);

  const [, setCookies] = useCookies([COOKIE_MUTED]);

  const musicButtonClick = () => {
    if (songPlaying === 'PLAYING') {
      setSongPlaying('PAUSED');
      setCookies(COOKIE_MUTED, 'true');
    } else if (songPlaying === 'PAUSED' || songPlaying === 'STOPPED') {
      setCookies(COOKIE_MUTED, 'false');
      setSongPlaying('PLAYING');
    }
  };

  const MusicButtons = () => (
    <Badge badgeContent={0} color="secondary">
      {songPlaying === 'STOPPED' ? (
        <PlayArrow />
      ) : songPlaying === 'PLAYING' ? (
        <MusicNote />
      ) : (
        <MusicOff />
      )}
    </Badge>
  );

  const { user } = useContext(UserContext);

  return (
    <Fragment>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => {
              setSidebarOpen(true);
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            World of Engineering
          </Typography>
          <FillContainer />
          <ToolbarIcon>
            <Button
              color="inherit"
              onClick={() => {
                history.push(`/${TopbarPages.DOWNLOAD}`);
              }}
              variant="outlined"
              startIcon={
                user.roles.includes('USER') ? <GetApp /> : <ShoppingCart />
              }
            >
              {user.roles.includes('USER') ? 'Download' : 'Get game'}
            </Button>
            <Button
              color="inherit"
              variant="outlined"
              style={{
                marginLeft: theme.spacing(3),
                marginRight: theme.spacing(1),
              }}
              onClick={() => {
                if (user.token) history.push(`/${TopbarPages.SCREWS}`);
                else history.push(`/${TopbarPages.SIGNIN}`);
              }}
            >
              <img
                src="screw.ico"
                alt="screws"
                style={{ width: 32, height: 32, alignSelf: 'center' }}
              />
              <NumberText
                pointerCursor={true}
                amount={user.screws}
                prefix="Screws: "
                style={{ alignSelf: 'center' }}
              />
            </Button>
            <IconButton color="inherit" onClick={musicButtonClick}>
              <Badge badgeContent={0} color="secondary">
                <MusicButtons />
              </Badge>
            </IconButton>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => {
                if (user.email) history.push(`/${TopbarPages.PROFILE}`);
                else history.push(`/${TopbarPages.SIGNIN}`);
              }}
            >
              <AccountCircle
                color={user.roles.includes('USER') ? 'secondary' : 'inherit'}
              />
            </IconButton>
          </ToolbarIcon>
          <ToolbarIconMobile onClick={musicButtonClick}>
            <IconButton color="inherit">
              <MusicButtons />
            </IconButton>
          </ToolbarIconMobile>
          <ToolbarIconMobile>
            <IconButton
              color="inherit"
              onClick={(event: React.MouseEvent<HTMLElement>) => {
                setMoreMenuAnchor(event.currentTarget);
              }}
            >
              <More />
            </IconButton>
          </ToolbarIconMobile>
        </Toolbar>
      </AppBar>

      <Menu
        anchorEl={moreMenuAnchor}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={!!moreMenuAnchor}
        onClose={() => {
          setMoreMenuAnchor(null);
        }}
      >
        <MenuItem
          onClick={() => {
            if (user.username) history.push(`/${TopbarPages.PROFILE}`);
            else history.push(`/${TopbarPages.SIGNIN}`);
          }}
        >
          <IconButton color="inherit">
            <AccountCircle />
          </IconButton>
          <Typography>Profile</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            history.push(`/${TopbarPages.DOWNLOAD}`);
          }}
        >
          <IconButton>
            {user.roles.includes('USER') ? <GetApp /> : <ShoppingCart />}
          </IconButton>
          <Typography>
            {user.roles.includes('USER') ? 'Download' : 'Get game'}
          </Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            if (user.token) history.push(`/${TopbarPages.SCREWS}`);
            else history.push(`/${TopbarPages.SIGNIN}`);
          }}
        >
          <NumberText
            pointerCursor={true}
            amount={user.screws}
            prefix="Screws: "
            style={{ alignSelf: 'center' }}
          />
        </MenuItem>
      </Menu>
      <Menu
        anchorEl={accountMenuAnchor}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={!!accountMenuAnchor}
        onClose={() => {
          setAccountMenuAnchor(null);
        }}
      >
        <MenuItem
          onClick={() => {
            if (user.email) history.push(`/${TopbarPages.PROFILE}`);
            else history.push(`/${TopbarPages.SIGNIN}`);
          }}
        >
          <IconButton color="inherit">
            <AccountCircle />
          </IconButton>
          <Typography>Login</Typography>
        </MenuItem>
      </Menu>

      <SigninDialog
        open={
          pageParam === TopbarPages.SIGNIN || pageParam === TopbarPages.REGISTER
        }
        onClose={() => {
          history.goBack();
        }}
      />

      <ScrewsDialog
        open={pageParam === TopbarPages.SCREWS}
        onClose={() => {
          history.goBack();
        }}
      />

      <ProfileDialog
        open={pageParam === TopbarPages.PROFILE}
        onClose={() => {
          history.goBack();
        }}
      />
      <PaymentDialog
        open={
          pageParam === TopbarPages.DOWNLOAD ||
          pageParam === TopbarPages.DOWNLOAD_REDIRECT
        }
        onClose={() => {
          history.goBack();
        }}
      />
      <Sidebar
        open={sidebarOpen}
        onClose={() => {
          setSidebarOpen(false);
        }}
      />
    </Fragment>
  );
};

const FillContainer = styled(Box)(({ theme }) => ({
  flexGrow: 1,
}));

const ToolbarIcon = styled(Box)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));
const ToolbarIconMobile = styled(Box)(({ theme }) => ({
  display: 'flex',
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}));
