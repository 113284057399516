import React from 'react';
import { Box, Typography, Link, Button, useTheme } from '@mui/material';
import { useApi } from '../../api/api';
import { useEffect } from 'react';
import { useContext } from 'react';
import { SnackbarContext } from '../../contexts/snackbar.context';
import { ButtonHeightCircularProgress } from '../ButtonHeightCircularProgress';

export const AboutPage = () => {
  const {
    sendRequest,
    data,
    error: errorMessage,
    loading,
  } = useApi('/misc/health', 'GET');
  const { setMessages } = useContext(SnackbarContext);
  const theme = useTheme();

  useEffect(() => {
    if (!!data && errorMessage.message)
      setMessages((m) => ({ ...m, error: errorMessage.message }));
    else if (!!data && !errorMessage.message)
      setMessages((m) => ({ ...m, success: 'Connection Successful' }));
  }, [data, errorMessage, setMessages]);

  return (
    <Box>
      <Typography
        variant="h3"
        style={{ color: theme.palette.text.primary, padding: theme.spacing(2) }}
      >
        About page
      </Typography>
      <Typography
        style={{ color: theme.palette.text.primary, padding: theme.spacing(2) }}
      >
        This page will be filled with info about the story behind the
        development, and other ways to interact with the developer.
      </Typography>
      <Link
        style={{ padding: theme.spacing(2) }}
        href={'https://discord.gg/VvqvxZ4gJs'}
      >
        discord link
      </Link>
      <Button onClick={sendRequest} variant="outlined" color="secondary">
        {loading ? <ButtonHeightCircularProgress /> : 'Server Connection Test'}
      </Button>
    </Box>
  );
};

export default AboutPage;
