import { Dispatch, SetStateAction, useState } from 'react';
import { useContext } from 'react';
import { useEffect } from 'react';
import { createContext } from 'react';
import { useApi } from '../api/api';
import { SnackbarContext } from './snackbar.context';
import _ from 'lodash';

export type Role =
  | 'USER'
  | 'ADMIN'
  | 'DEVELOPER'
  | 'ENDORSED'
  | 'DONATORL1'
  | 'DONATORL2'
  | 'DONATORL3';

export type PublicUser = {
  displayName: string;
  roles: Role[];
};

export type UserState = {
  username: string;
  email: string;
  displayName: string;
  roles: Role[];
  screws: number;
  screwsLifetime: number;
  created: Date;
  token: string;
};

export const initialUserState: UserState = {
  username: '',
  email: '',
  displayName: '',
  roles: [],
  screws: 0,
  screwsLifetime: 0,
  created: new Date(),
  token: '',
};

export const UserContext = createContext({
  user: initialUserState,
  setUser: {} as Dispatch<SetStateAction<UserState>>,
  refreshUser: {} as () => void,
});

export const UserProvider = (props: { children: JSX.Element }) => {
  const [user, setUser] = useState(initialUserState);
  const { setMessages } = useContext(SnackbarContext);

  const {
    data: userData,
    error: errorGettingUser,
    sendRequest,
  } = useApi<UserState>('users/me', 'GET');

  useEffect(() => {
    if (userData && !_.isEqual(userData, user) && !errorGettingUser.message) {
      setUser((u) => ({ ...u, ...userData }));
    } else if (userData && errorGettingUser.message) {
      setMessages((m) => ({ ...m, error: 'Error getting user' }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorGettingUser, setMessages, userData]);

  const refreshUser = () => {
    sendRequest(undefined, undefined, user.token);
  };

  return (
    <UserContext.Provider value={{ user, setUser, refreshUser }}>
      {props.children}
    </UserContext.Provider>
  );
};
