import {
  Box,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/system';
import { useRef, useState } from 'react';

export const HomePage = () => {
  const itemContainerRef = useRef<HTMLDivElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const theme = useTheme();

  const [hoveringIndex, setHoveringIndex] = useState(-1);
  const [hoveringBrokenIndex, setHoveringBrokenIndex] = useState(-1);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <ScreenContainer ref={containerRef}>
      <Typography variant="h4" style={{ color: theme.palette.text.primary }}>
        Demos
      </Typography>
      <Grid container spacing={2} columns={{ lg: 5, md: 3, sm: 2, xs: 1 }}>
        {ids?.map((videoUrl, index) => (
          <Grid item xs={1} sm={1} md={1} lg={1} key={index}>
            <>
              <Paper
                ref={itemContainerRef}
                style={{
                  overflow: 'hidden',
                }}
              >
                <Typography
                  style={{
                    backgroundColor: '#1f4f7eab',
                    zIndex: 1,
                  }}
                >
                  {titles[index]}
                </Typography>
                {index !== hoveringIndex ? (
                  <img
                    style={{
                      width: '100%',
                      height: '100%',
                      overflow: 'hidden',
                      marginBottom: -4,
                    }}
                    onClick={(e) => {
                      if (isMobile) {
                        setHoveringIndex(index);
                      }
                    }}
                    onPointerEnter={() => {
                      if (!isMobile) {
                        setHoveringIndex(index);
                      }
                    }}
                    src={'/assets/images/thumbnails/' + titles[index] + '.png'}
                    alt={titles[index]}
                  />
                ) : (
                  <iframe
                    style={{
                      width: '100%',
                      height: '100%',
                      overflow: 'hidden',
                      marginBottom: -4,
                    }}
                    onPointerLeave={() => {
                      if (!isMobile) {
                        setHoveringIndex(-1);
                      }
                    }}
                    src={`https://www.youtube.com/embed/${videoUrl
                      .split('/')
                      .pop()}?autoplay=1${
                      titles[index] === 'Most Advanced Playable Game Piano'
                        ? ''
                        : '&mute=1'
                    }`}
                    title={'showcase' + index}
                    allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  />
                )}
              </Paper>
            </>
          </Grid>
        ))}
      </Grid>

      <Typography
        variant="h4"
        style={{ marginTop: 40, color: theme.palette.text.primary }}
      >
        Fun Videos of previously broken things
      </Typography>

      <Grid container spacing={2} columns={{ lg: 5, md: 3, sm: 2, xs: 1 }}>
        {bugIds.map((videoUrl, index) => (
          <Grid item xs={1} sm={1} md={1} lg={1} key={index + 'broken'}>
            <Paper
              ref={itemContainerRef}
              style={{
                overflow: 'hidden',
              }}
            >
              <Typography
                style={{
                  backgroundColor: '#1f4f7eab',
                  zIndex: 1,
                }}
              >
                {bugTitles[index]}
              </Typography>
              {index !== hoveringBrokenIndex ? (
                <img
                  style={{
                    width: '100%',
                    height: '100%',
                  }}
                  onClick={(e) => {
                    if (isMobile) {
                      setHoveringBrokenIndex(index);
                    }
                  }}
                  onPointerEnter={() => {
                    if (!isMobile) {
                      setHoveringBrokenIndex(index);
                    }
                  }}
                  src={'/assets/images/thumbnails/' + bugTitles[index] + '.png'}
                  alt={bugTitles[index]}
                />
              ) : (
                <iframe
                  style={{
                    width: '100%',
                    height: '100%',
                  }}
                  onPointerLeave={() => {
                    if (!isMobile) {
                      setHoveringBrokenIndex(-1);
                    }
                  }}
                  src={`https://www.youtube.com/embed/${videoUrl
                    .split('/')
                    .pop()}?autoplay=1&mute=1`}
                  title={'bug' + index}
                  allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                />
              )}
            </Paper>
          </Grid>
        ))}
      </Grid>
    </ScreenContainer>
  );
};

const ScreenContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  color: theme.palette.text.primary,
}));

export default HomePage;

const titles = [
  'Global Illumination and Pretty Clouds (WIP)',
  'Machine Learning Image Classification',
  'Instancing',
  'Blueprint System',
  'Morph Target Animation',
  'Animation Interpolation and Harvest System',
  'Chunk Serialization and Compression',
  'Screen Space Ray Tracing',
  'Optimized Performance',
  '2K Normal Maps',
  'Cascaded Shadow Mapping',
  'Caves',
  'Jetpack Controls',
  'External Inventory',
  'Day, Season, Constellation',
  'Debugging',
  'Image Filters',
  'Frustum Culling',
  'Where It All Began',
  'Multiplayer (WIP)',
  'Realistic Physics',
  'Point Lights',
  'Raycasting',
  'Extensive Settings',
  'Specular Lighting',
  'Tech Tree (WIP)',
  'Stacked Translucency',
  'Multilingual Text Support',
  'Most Advanced Playable Game Piano',
  'Advanced Collision',
  'Dynamic Chunk Generation',
  'Camera Projections',
  'Blueprints',
];

const ids = [
  'https://youtu.be/Ovs9-G0gAsw',
  'https://youtu.be/88fIH68PTNg',
  'https://youtu.be/G76MMGqGeNI',
  'https://youtu.be/dHZc4JxPgXI',
  'https://youtu.be/MGvpWU6dbBU',
  'https://youtu.be/aSIpsHSMpoU',
  'https://youtu.be/xIPcslVjtr8',
  'https://youtu.be/1FCRiJ1VGKw',
  'https://youtu.be/ptufSqfPuO0',
  'https://youtu.be/pGVN_i0BIIA',
  'https://youtu.be/MI362gMWDKA',
  'https://youtu.be/1PtCCLvtSDs',
  'https://youtu.be/0liXhFexaJo',
  'https://youtu.be/AX14uiYnYRM',
  'https://youtu.be/SRrEWtUVz7s',
  'https://youtu.be/BkKpUlt0030',
  'https://youtu.be/DtdILfvkNKc',
  'https://youtu.be/6qnx51MYjW8',
  'https://youtu.be/o3jFLrgG8ds',
  'https://youtu.be/AcUZINoqKa0',
  'https://youtu.be/ED-1QxSWJZ8',
  'https://youtu.be/q2UF4jxnn84',
  'https://youtu.be/weSytlqNCf4',
  'https://youtu.be/HX5G1aQkz48',
  'https://youtu.be/4Qn_nD7qKdM',
  'https://youtu.be/XEy0r36KpOk',
  'https://youtu.be/1mEBFAFpvtk',
  'https://youtu.be/VWmeex-35HY',
  'https://youtu.be/6dTgbPr6aX0',
  'https://youtu.be/So9sfYW28DE',
  'https://youtu.be/KxqLCZmKHIQ',
  'https://youtu.be/MxuzaeAHofk',
  'https://youtu.be/BJJs-TdP1vk',
];

const bugTitles = [
  'Bad Coordinate System',
  'Everything is Wood',
  'Piano Slide',
  'History of Chunks 4',
  'History of Chunks 3',
  'History of Chunks 2',
  'History of Chunks 1',
  'Broken Anti-Aliasing',
  'Bad Ray Tracing',
  'Bad Cave Noise',
];

const bugIds = [
  'https://youtu.be/Cc-o2xyNlzw',
  'https://youtu.be/RAGgUglOHmQ',
  'https://youtu.be/UGOvJRTRxBA',
  'https://youtu.be/cnlE9pUuQlc',
  'https://youtu.be/gAm9KqEcBtk',
  'https://youtu.be/kZe2_kbOgq8',
  'https://youtu.be/pRC5U_Wvfes',
  'https://youtu.be/cAAOtDVxo5g',
  'https://youtu.be/p1pSYNqrgIQ',
  'https://youtu.be/8YWHYXxJy24',
];
