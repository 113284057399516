import { AccountCircle, Face, Lock, MailOutline } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputAdornment,
  TextField,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/system';
import { Fragment, useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useApi } from '../../api/api';
import { SnackbarContext } from '../../contexts/snackbar.context';
import { UserContext, UserState } from '../../contexts/user.context';
import { TopbarPages } from '../Topbar';
import { TypographyCursor } from '../TypographyCursor';

export const SigninDialog = (props: { open: boolean; onClose: () => void }) => {
  const { open, onClose } = props;
  const history = useHistory();
  const { pageParam } = useParams<{
    pageParam: string | undefined;
    innerPageParam: string | undefined;
  }>();
  // const [showRegister, setShowRegister] = useState(false);

  const validEmail = (email: string) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const validPassword = (password: string) => {
    return (
      password.length >= 8 &&
      /\d/.test(password) &&
      /[a-z]/.test(password) &&
      /[A-Z]/.test(password) &&
      /[^0-9a-zA-Z]/.test(password)
    );
  };
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    passwordConfirm: '',
    displayName: '',
  });

  useEffect(() => {
    setFormData({
      username: '',
      email: '',
      password: '',
      passwordConfirm: '',
      displayName: '',
    });
  }, [open]);

  const theme = useTheme();
  const { setUser } = useContext(UserContext);
  const { setMessages } = useContext(SnackbarContext);

  const {
    data: loginResponse,
    error: errorLogin,
    loading: loadingLogin,
    sendRequest: login,
  } = useApi<UserState>('users/login', 'POST');

  const {
    data: registerResponse,
    error: errorRegister,
    loading: loadingRegister,
    sendRequest: register,
  } = useApi<UserState>('users/register', 'POST');

  useEffect(() => {
    if (!!loginResponse && !errorLogin.message) {
      setMessages((m) => ({ ...m, success: 'Log in success' }));
      setUser(loginResponse);
      onClose();
    }
    if (!!registerResponse && !errorRegister.message) {
      setMessages((m) => ({ ...m, success: 'Registration success' }));
      history.goBack();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginResponse, registerResponse]);

  useEffect(() => {
    setMessages((m) => ({
      ...m,
      error: (errorLogin?.message || errorRegister?.message) ?? '',
    }));
  }, [errorRegister, errorLogin, setMessages]);

  const submit = async () => {
    if (pageParam === TopbarPages.SIGNIN)
      login(
        {
          username: formData.username,
          password: formData.password,
        },
        undefined
      );
    else
      register({
        username: formData.username,
        displayName: formData.displayName,
        password: formData.password,
        email: formData.email,
      });
  };

  return (
    <Fragment>
      <Dialog
        open={open}
        onClose={() => {
          pageParam === TopbarPages.REGISTER ? history.goBack() : onClose();
        }}
      >
        <Box
          style={{ padding: theme.spacing(2) }}
          onKeyPress={(event) => {
            if (event.key === 'Enter') {
              event.preventDefault();
              submit();
            }
          }}
        >
          <DialogTitle>
            {pageParam === TopbarPages.REGISTER ? 'Register' : 'Log in'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {`${
                pageParam === TopbarPages.REGISTER ? 'Register' : 'Log in'
              } to access World of Engineering`}
            </DialogContentText>
            <TextField
              autoFocus
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setFormData({ ...formData, username: event.target.value });
              }}
              onSubmit={submit}
              error={formData.username.length === 0}
              helperText={
                pageParam === TopbarPages.REGISTER
                  ? 'YOU CANNOT CHANGE THIS LATER!! Must not be empty'
                  : 'Must not be empty'
              }
              variant="outlined"
              label="Username"
              required
              fullWidth
              margin="normal"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircle />
                  </InputAdornment>
                ),
              }}
            />
            {pageParam === TopbarPages.REGISTER && (
              <TextField
                required
                label="Email"
                helperText="Must be a valid email"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setFormData({ ...formData, email: event.target.value });
                }}
                error={formData.email.length > 0 && !validEmail(formData.email)}
                variant="outlined"
                fullWidth
                margin="normal"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MailOutline />
                    </InputAdornment>
                  ),
                }}
              />
            )}
            <TextField
              required
              label="Password"
              helperText="Must contain at least 8 characters, an Upper and lowercase character, a number, and a special character"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setFormData({ ...formData, password: event.target.value });
              }}
              variant="outlined"
              error={
                formData.password.length === 0 ||
                (formData.password.length > 0 &&
                  !validPassword(formData.password))
              }
              type={'password'}
              fullWidth
              margin="normal"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Lock />
                  </InputAdornment>
                ),
              }}
            />
            {pageParam === TopbarPages.REGISTER && (
              <Fragment>
                <TextField
                  required
                  helperText="Password must match"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setFormData({
                      ...formData,
                      passwordConfirm: event.target.value,
                    });
                  }}
                  error={
                    formData.password.length > 0 &&
                    formData.password !== formData.passwordConfirm
                  }
                  label="Confirm Password"
                  variant="outlined"
                  type="password"
                  fullWidth
                  margin="normal"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Lock />
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  required
                  label="Display Name"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setFormData({
                      ...formData,
                      displayName: event.target.value,
                    });
                  }}
                  error={formData.displayName.length === 0}
                  variant="outlined"
                  helperText="Must not be empty"
                  fullWidth
                  margin="normal"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Face />
                      </InputAdornment>
                    ),
                  }}
                />
              </Fragment>
            )}
          </DialogContent>
          <DialogActions>
            <SpacedButton
              style={{ height: 44 }}
              onClick={() => {
                if (pageParam === TopbarPages.REGISTER) history.goBack();
                onClose();
              }}
              color="primary"
              variant="outlined"
            >
              <TypographyCursor>Cancel</TypographyCursor>
            </SpacedButton>
            <SpacedButton
              onClick={() => {
                pageParam === TopbarPages.REGISTER
                  ? history.goBack()
                  : history.push(`${TopbarPages.REGISTER}`);
              }}
              style={{ height: 44 }}
              color="primary"
              variant="contained"
            >
              <TypographyCursor>
                {pageParam === TopbarPages.REGISTER ? 'Go Back' : 'Register'}
              </TypographyCursor>
            </SpacedButton>
            <SpacedButton
              disabled={
                (pageParam === TopbarPages.REGISTER &&
                  (!formData.email ||
                    !validEmail(formData.email) ||
                    !formData.password ||
                    formData.password !== formData.passwordConfirm ||
                    !validPassword(formData.password) ||
                    !formData.displayName ||
                    !formData.username)) ||
                (pageParam === TopbarPages.SIGNIN &&
                  (!formData.username ||
                    !formData.password ||
                    !validPassword(formData.password)))
              }
              style={{ height: 44, width: 120 }}
              onClick={submit}
              color="primary"
              variant="contained"
            >
              {loadingLogin || loadingRegister ? (
                <CircularProgress color="secondary" />
              ) : (
                <TypographyCursor>
                  {pageParam === TopbarPages.REGISTER ? 'Register' : 'Submit'}
                </TypographyCursor>
              )}
            </SpacedButton>
          </DialogActions>
        </Box>
      </Dialog>
    </Fragment>
  );
};

const SpacedButton = styled(Button)(({ theme }) => ({
  marginRight: theme.spacing(3),
  marginLeft: theme.spacing(3),
  padding: theme.spacing(1),
}));
