import {
  Button,
  Card,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  Zoom,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
// import { styled } from '@mui/system';
import { Fragment, useEffect, useRef, useState } from 'react';
import Xarrow from 'react-xarrows/lib';
import defaultTech from '../resources/defaultTechTree.json';
import { entities } from '../resources/entities';
import DragMove from './DragMove';
import {
  encodeXML,
  formatAnswer,
  formatNumberArray,
  formatQuestion,
  getNodeColor,
  getNodeName,
} from './Utils';

enum TechNodeType {
  INVENTION,
  KNOWLEDGE,
  DISCOVERY,
}
enum TechNodeLevel {
  STONE,
  BRONZE,
  IRON,
  RENAISSANCE,
  INDUSTRIAL,
  ATOMIC,
  SPACE,
  CUTTING_EDGE,
  FUTURE,
}

export type TechNode = {
  id: number;
  type: TechNodeType;
  unlocked: boolean;
  name: string;
  description: string;
  level: TechNodeLevel;
  unlocks: string;
  prerequisites: number[]; // technode vector index
  explanation: string;
  question: string;
  inputs: string; // each outer array is list of potential values to ${1}, ${2}, etc.
  answers: string;
  x: number;
  y: number;
};

const scrollIncrement = 2;
const maxZoom = 20;
const tech = [...defaultTech] as TechNode[];
export const TechTree = () => {
  const [rerenderCount, setRerenderCount] = useState(0);
  const [zoom, setZoom] = useState(4);
  const [questionPreset, setQuestionsPreset] = useState(0);
  const [translate, setTranslate] = useState({
    x: window.innerWidth / 2,
    y: window.innerHeight / 2,
  });

  const doingDragNode = useRef(false);

  const [draggingNode, setDraggingNode] = useState({
    id: -1,
    x: 0,
    y: 0,
  });

  const [selected, setSelected] = useState<number>();
  const [workspaceLines, setWorkspaceLines] = useState<JSX.Element[]>([]);
  const [workspaceNodes, setWorkspaceNodes] = useState<JSX.Element[]>([]);

  const renderUnselectedTooltipContent = (node: TechNode) => {
    return (
      <div
        style={{
          paddingBottom: 5,
          width: 500,
          maxHeight: 600,
          padding: 10,
        }}
        onPointerDown={(e) => {
          e.stopPropagation();
        }}
        onWheel={(e) => {
          e.stopPropagation();
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Typography variant="h4">{node.name}</Typography>

        <Typography variant="subtitle2">{'id: ' + node.id}</Typography>

        <Typography variant="body1" style={{ color: getNodeColor(node.level) }}>
          {'tech level: ' + getNodeName(node.level)}
        </Typography>

        <Typography variant="body1">{node.description}</Typography>

        {node.unlocks.length !== 0 && (
          <>
            <Subtitle>Entity Unlocks:</Subtitle>
            {formatNumberArray(node.unlocks).map((u, ind) => (
              <Tooltip
                key={`unselected entity ${ind}`}
                title={`Entity id: ${entities[u].id} name: ${entities[u].name}`}
                arrow
                TransitionComponent={Zoom}
              >
                <img
                  src={`/assets/images/entities/${u}.png`}
                  alt={`Entity id: ${entities[u].id} name: ${entities[u].name}`}
                  onError={(e: any) => {
                    e.target.onerror = null;
                    e.target.src = `/assets/images/entities/default.png`;
                  }}
                />
              </Tooltip>
            ))}
          </>
        )}

        <Subtitle>Question sample:</Subtitle>
        <Typography>
          {formatQuestion(node.question, node.inputs, questionPreset)}
        </Typography>
        <Subtitle>Answer sample:</Subtitle>
        <Typography>{formatAnswer(node.answers, questionPreset)}</Typography>
      </div>
    );
  };

  const renderSelectedTooltipContent = (node: TechNode) => {
    return (
      <div
        style={{
          paddingBottom: 5,
          width: 500,
          maxHeight: 800,
          overflow: 'auto',
          padding: 8,
          paddingRight: 16,
        }}
        onPointerDown={(e) => {
          e.stopPropagation();
        }}
        onWheel={(e) => {
          e.stopPropagation();
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <TextField
          fullWidth
          multiline
          onChange={(t) => {
            node.name = t.target.value;
            setRerenderCount((c) => c + 1);
          }}
          value={node.name}
        />

        <FormControl component="fieldset">
          <RadioGroup
            row
            value={node.type}
            onChange={(t) => {
              node.type = Number(t.target.value) as TechNodeType;
              setRerenderCount((c) => c + 1);
            }}
          >
            <FormControlLabel
              value={TechNodeType.INVENTION}
              control={<Radio />}
              label="Invention"
            />
            <FormControlLabel
              value={TechNodeType.KNOWLEDGE}
              control={<Radio />}
              label="Knowledge"
            />
            <FormControlLabel
              value={TechNodeType.DISCOVERY}
              control={<Radio />}
              label="Discovery"
            />
          </RadioGroup>
        </FormControl>

        <TextField
          fullWidth
          onChange={(t) => {
            node.description = t.target.value;
            setRerenderCount((c) => c + 1);
          }}
          value={node.description}
          multiline
        />

        <Subtitle>Entity Unlocks:</Subtitle>
        <TextField
          fullWidth
          onChange={(t) => {
            node.unlocks = t.target.value;
            setRerenderCount((c) => c + 1);
          }}
          value={node.unlocks}
          multiline
          helperText="id1,id2..."
        />

        <Subtitle>Question:</Subtitle>
        <TextField
          fullWidth
          onChange={(t) => {
            node.question = t.target.value;
            setRerenderCount((c) => c + 1);
          }}
          value={node.question}
          multiline
        />
        <Subtitle>Inputs:</Subtitle>
        <TextField
          fullWidth
          onChange={(t) => {
            node.inputs = t.target.value;
            setRerenderCount((c) => c + 1);
          }}
          value={node.inputs}
          multiline
          helperText="arg1Choice1,arg1Choice2;arg2Choice1,arg2Choice2;..."
        />
        <Subtitle>Answers:</Subtitle>
        <TextField
          fullWidth
          onChange={(t) => {
            node.answers = t.target.value;
            setRerenderCount((c) => c + 1);
          }}
          value={node.answers}
          multiline
          helperText="choice1Answer,choice2Answer,..."
        />
        <Subtitle>Question Input Choice</Subtitle>
        <FormControl component="fieldset">
          <RadioGroup
            row
            value={questionPreset}
            onChange={(t) => {
              setQuestionsPreset(Number(t.target.value));
            }}
          >
            <FormControlLabel value={0} control={<Radio />} label="0" />
            <FormControlLabel value={1} control={<Radio />} label="1" />
            <FormControlLabel value={2} control={<Radio />} label="2" />
            <FormControlLabel value={3} control={<Radio />} label="3" />
          </RadioGroup>
        </FormControl>
      </div>
    );
  };

  const renderNodeImage = (node: TechNode, isSelected: boolean) => {
    let imageReloadAttempted = false;
    return (
      <Card
        id={'node' + node.id.toString() + isSelected}
        onClick={(e) => {
          if (!e.shiftKey) setSelected(node.id);
          else if (selected !== node.id && selected !== undefined) {
            if (tech[selected].prerequisites.includes(node.id)) {
              tech[selected].prerequisites = tech[
                selected
              ].prerequisites.filter((p) => p !== node.id);
            } else {
              tech[selected].prerequisites.push(node.id);
            }
            setSelected(undefined);
          }
          e.stopPropagation();
        }}
        style={{
          position: 'absolute',
          width: 8 * zoom,
          height: 8 * zoom,
          left: node.x * zoom * 5,
          top: node.y * zoom * 5,
          transform: `translateX(${
            translate.x + (draggingNode.id === node.id ? draggingNode.x : 0)
          }px) translateY(${
            translate.y + (draggingNode.id === node.id ? draggingNode.y : 0)
          }px)`,
          backgroundColor: getNodeColor(node.level),
          borderWidth: selected === node.id ? 2 : 0,
          borderColor: '#e9e76bb2',
          borderStyle: 'solid',
        }}
      >
        <FilledDragMove
          onDragMove={(e) => {
            setDraggingNode((prev) => {
              return {
                id: node.id,
                x: prev.x + e.movementX,
                y: prev.y + e.movementY,
              };
            });
            e.stopPropagation();
            doingDragNode.current = true;
          }}
          onDoneDrag={(e) => {
            if (e.buttons === 0) {
              node.x += draggingNode.x / zoom / 5;
              node.y += draggingNode.y / zoom / 5;
              setDraggingNode((prev) => {
                return {
                  id: -1,
                  x: 0,
                  y: 0,
                };
              });
              doingDragNode.current = false;
            }
          }}
          onPointerDown={() => {}}
        >
          <NointeractImage
            alt={`Node id: ${node.id} name: ${node.name}`}
            src={`${process.env.PUBLIC_URL}/assets/images/tech/${node.id}.png`}
            onError={(e: any) => {
              if (!imageReloadAttempted) {
                imageReloadAttempted = true;
                e.target.onerror = null;
                e.target.src = `${process.env.PUBLIC_URL}/assets/images/tech/default.png`;
              }
            }}
          />
        </FilledDragMove>
      </Card>
    );
  };

  const renderTree = () => {
    return tech.map((node, index) => (
      <Fragment key={`node${index}`}>
        {selected === node.id && (
          <NoMaxWidthTooltip
            style={{
              width: 500,
              maxHeight: 600,
              maxWidth: 'none',
            }}
            arrow
            TransitionComponent={Zoom}
            placement="right"
            open={true}
            onOpen={() => {}}
            onClose={() => {}}
            title={renderSelectedTooltipContent(node)}
          >
            {renderNodeImage(node, true)}
          </NoMaxWidthTooltip>
        )}
        <NoMaxWidthTooltip
          style={{
            width: 500,
            maxHeight: 600,
            maxWidth: 'none',
          }}
          arrow
          TransitionComponent={Zoom}
          placement="right"
          title={
            selected === node.id ? <></> : renderUnselectedTooltipContent(node)
          }
        >
          {renderNodeImage(node, false)}
        </NoMaxWidthTooltip>
      </Fragment>
    ));
  };

  const renderLines = () => {
    return tech
      .map((node) =>
        node.prerequisites.map((parent) => (
          <Xarrow
            key={'arrow' + node.id.toString() + tech[parent].id.toString()}
            start={'node' + node.id.toString() + 'false'} //can be react ref
            end={'node' + tech[parent].id.toString() + 'false'} //or an id
            strokeWidth={
              selected === node.id || selected === parent
                ? zoom * 0.4
                : zoom * 0.2
            }
            curveness={0}
            animateDrawing={1}
            dashness={
              (selected === node.id || selected === parent) && {
                strokeLen: 10,
                nonStrokeLen: 4,
                animation: 5,
              }
            }
          />
        ))
      )
      .reduce((prev, next) => prev.concat(next));
  };

  useEffect(() => {
    setWorkspaceNodes(renderTree());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [translate, zoom, selected, draggingNode, rerenderCount, questionPreset]);

  useEffect(() => {
    setWorkspaceLines(renderLines());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [translate, zoom, selected, draggingNode]);

  return (
    <FilledDragMove
      onWheel={(e) => {
        if (e.deltaY < 0 && zoom < maxZoom) setZoom(zoom + scrollIncrement);
        else if (e.deltaY > 0 && zoom > scrollIncrement)
          setZoom(zoom - scrollIncrement);
      }}
      onClick={(e) => {
        setSelected(undefined);
      }}
    >
      <Workspace
        onDragMove={(e) => {
          if (!doingDragNode.current) {
            setTranslate((prev) => {
              return {
                x: prev.x + e.movementX,
                y: prev.y + e.movementY,
              };
            });
          }
        }}
      >
        {workspaceLines}
        {workspaceNodes}
        <WorkspaceButton
          onClick={() => {
            const element = document.createElement('a');
            const bytes = new TextEncoder().encode(
              JSON.stringify(tech, null, 2)
            );
            const blob = new Blob([bytes], {
              type: 'application/json;charset=utf-8',
            });
            element.href = URL.createObjectURL(blob);
            element.download = 'tech.json';
            document.body.appendChild(element); // Required for this to work in FireFox
            element.click();
          }}
        >
          Save to JSON File (For loading into this tool)
        </WorkspaceButton>
        <WorkspaceButton
          onClick={() => {
            const element = document.createElement('a');
            const bytes = new TextEncoder().encode(encodeXML(tech));
            const blob = new Blob([bytes], {
              type: 'application/xml;charset=utf-8',
            });
            element.href = URL.createObjectURL(blob);
            element.download = 'tech.xml';
            document.body.appendChild(element); // Required for this to work in FireFox
            element.click();
          }}
        >
          Save to XML File (For in game)
        </WorkspaceButton>
      </Workspace>
    </FilledDragMove>
  );
};

const FilledDragMove = styled(DragMove)(({ theme }) => ({
  width: '100%',
  height: '100%',
  overflow: 'hidden',
}));

const Workspace = styled(DragMove)(({ theme }) => ({
  width: '96%',
  marginLeft: '2%',
  marginRight: '2%',
  marginTop: '1%',
  marginBottom: '1%',
  height: '98%',
  overflow: 'hidden',
  position: 'relative',
  overflowY: 'hidden',
  backgroundColor: theme.palette.customWorkspaceBackground.main,
}));

const WorkspaceButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.light,
  margin: theme.spacing(2),
}));

const NointeractImage = styled('img')(({ theme }) => ({
  userSelect: 'none',
  pointerEvents: 'none',
  height: '100%',
  width: '100%',
}));

const NoMaxWidthTooltip = styled(({ theme, className, ...props }: any) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 'none',
  },
});

const Subtitle = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2),
  fontSize: 16,
}));

export default TechTree;
