import {
  Box,
  Button,
  IconButton,
  styled,
  Typography,
  useTheme,
} from '@mui/material';
import {
  GetApp,
  NavigateBefore,
  NavigateNext,
  Publish,
  SkipPrevious,
} from '@mui/icons-material';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useApi } from '../../../api/api';
import { TOPIC_CREATE_SCREWS } from '../../../constants';
import { SnackbarContext } from '../../../contexts/snackbar.context';
import { PublicUser, Role, UserContext } from '../../../contexts/user.context';
import { TopbarPages } from '../../Topbar';
import { DevelopmentTopic } from './DevelopmentTopic';

export type ReplyThreadMessage = {
  message: string;
  creatorName: string;
};

export type Reply = {
  message: string;
  screws: number;
  creatorName: string;
  thread: ReplyThreadMessage[];
};

export type TopicState =
  | 'none'
  | 'committed'
  | 'inProgress'
  | 'done'
  | 'rejected';

export type Topic = {
  title: string;
  screws: number;
  description: string;
  creatorName: string;
  replies: Reply[];
  state: TopicState;
  daysEstimate: number;
};

export type UserMap = { [key: string]: Role[] };

interface Props {
  forum: boolean;
}
export const DevelopmentPage = (props: Props) => {
  const { forum } = props;
  const history = useHistory();
  const { setMessages } = useContext(SnackbarContext);
  const { user, refreshUser } = useContext(UserContext);
  const theme = useTheme();
  const [userMap, setUserMap] = useState<UserMap>();
  const [page, setPage] = useState(0);
  const [newTopic, setNewTopic] = useState(false);

  const {
    data,
    error: errorGetTopics,
    sendRequest: getTopics,
  } = useApi<{ topics: Topic[]; users: PublicUser[] }>('topic', 'GET');

  useEffect(() => {
    getTopics(undefined, { page, forum: forum ? 'true' : 'false' });
  }, [getTopics, page, forum]);

  useEffect(() => {
    if (data) {
      let map: { [key: string]: Role[] } = {};
      data.users.forEach((u) => {
        map[u.displayName] = u.roles;
      });
      setUserMap(map);
    }
  }, [data]);

  useEffect(() => {
    setMessages((m) => ({
      ...m,
      error: errorGetTopics.message ?? '',
    }));
  }, [errorGetTopics, setMessages]);

  function scrollToTop(bottom: boolean = false) {
    const anchor = document.querySelector(
      bottom ? '#back-to-bottom-anchor' : '#back-to-top-anchor'
    );
    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  const ScrollingBar = (props: { id?: string; bottom?: boolean }) => {
    return (
      <HorizontalContainer
        id={props.id}
        style={{ alignSelf: 'center', height: '5%', margin: 20 }}
      >
        <IconButton
          onClick={() => {
            setPage(0);
          }}
          disabled={page === 0}
        >
          <SkipPrevious />
        </IconButton>
        <IconButton
          onClick={() => {
            setPage(page - 1);
          }}
          disabled={page === 0}
        >
          <NavigateBefore />
        </IconButton>
        <Typography style={{ color: theme.palette.text.primary }}>{`Page ${
          page + 1
        }`}</Typography>
        <IconButton
          onClick={() => {
            setPage(page + 1);
          }}
          disabled={data?.topics.length !== 20}
        >
          <NavigateNext />
        </IconButton>
        <IconButton onClick={() => scrollToTop(props.bottom)}>
          {props.bottom ? <GetApp /> : <Publish />}
        </IconButton>
      </HorizontalContainer>
    );
  };

  if (!user.token) {
    return (
      <Box>
        <Typography
          variant="h3"
          style={{
            color: theme.palette.text.primary,
            padding: theme.spacing(2),
          }}
        >
          You must sign in to see this page
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          style={{
            color: theme.palette.text.primary,
            margin: theme.spacing(2),
          }}
          onClick={() => {
            history.push(`/${TopbarPages.SIGNIN}`);
          }}
        >
          Sign in
        </Button>
      </Box>
    );
  }

  return (
    <Box
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <ScrollingBar bottom={true} id="back-to-top-anchor" />
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Button
          disabled={!forum && user.screws < TOPIC_CREATE_SCREWS}
          variant="contained"
          color="secondary"
          onClick={() => setNewTopic(!newTopic)}
          style={{
            margin: theme.spacing(2),
          }}
        >
          {newTopic
            ? 'cancel'
            : forum
            ? 'Create Topic'
            : `Create Topic (${TOPIC_CREATE_SCREWS} Screws)`}
        </Button>

        {newTopic && (
          <DevelopmentTopic
            forum={forum}
            topic={{
              title: '',
              description: '',
              creatorName: user.displayName,
              screws: TOPIC_CREATE_SCREWS,
              replies: [],
              state: 'none',
              daysEstimate: 0,
            }}
            onClose={() => setNewTopic(false)}
            userMap={userMap}
            refresh={() => {
              getTopics(undefined, { page, forum: forum ? 'true' : 'false' });
              refreshUser();
            }}
          />
        )}
        {data?.topics.map((topic, topicIndex) => (
          <DevelopmentTopic
            forum={forum}
            key={topicIndex}
            userMap={userMap}
            topic={topic}
            refresh={() => {
              getTopics(undefined, { page, forum: forum ? 'true' : 'false' });
              refreshUser();
            }}
          />
        ))}
      </Box>

      <ScrollingBar id="back-to-bottom-anchor" />
    </Box>
  );
};

const HorizontalContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export default DevelopmentPage;
