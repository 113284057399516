import React, { useState } from 'react';

export type DragMoveProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  onDragMove?: React.PointerEventHandler<HTMLDivElement>;
  onDoneDrag?: React.PointerEventHandler<HTMLDivElement>;
};

export default function DragMove(props: DragMoveProps) {
  const { onDragMove, onDoneDrag, children, ...rest } = props;

  const [isDragging, setIsDragging] = useState(false);

  const handleStartDrag = (e: React.PointerEvent<HTMLDivElement>) => {
    setIsDragging(true);
  };

  const handleStopDrag = (e: React.PointerEvent<HTMLDivElement>) => {
    if (isDragging) {
      setIsDragging(false);
      onDoneDrag && onDoneDrag(e);
    }
  };

  const handlePointerMove = (e: React.PointerEvent<HTMLDivElement>) => {
    if (isDragging) onDragMove && onDragMove(e);
  };

  return (
    <div
      {...rest}
      onPointerDown={handleStartDrag}
      onPointerUp={handleStopDrag}
      onPointerLeave={handleStopDrag}
      onPointerMove={handlePointerMove}
    >
      {children}
    </div>
  );
}
