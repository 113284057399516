import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Slide,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { Close, ExitToApp, Redeem } from '@mui/icons-material';
import { forwardRef, useContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useApi } from '../../api/api';
import { COOKIE_TOKEN } from '../../contexts/initialLoader.context';
import { SnackbarContext } from '../../contexts/snackbar.context';
import { initialUserState, UserContext } from '../../contexts/user.context';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...(props as any)} />;
});

export const ProfileDialog = (props: {
  open: boolean;
  onClose: () => void;
}) => {
  const { open, onClose } = props;
  const { user, setUser } = useContext(UserContext);
  const [, setCookie] = useCookies([COOKIE_TOKEN]);
  const { messages, setMessages } = useContext(SnackbarContext);
  const theme = useTheme();

  const {
    data: redeemResponse,
    error: errorRedeem,
    loading: loadingRedeem,
    sendRequest: redeem,
  } = useApi<{ code: string }>('users/redeem', 'POST');

  useEffect(() => {
    if (!!redeemResponse && !errorRedeem.message) {
      setMessages((m) => ({ ...m, success: 'Redemption success' }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redeemResponse]);

  useEffect(() => {
    setMessages((m) => ({
      ...m,
      error: errorRedeem?.message,
    }));
  }, [errorRedeem, setMessages]);

  const [redeemCode, setRedeemCode] = useState('');
  const codeLength = '********-****-****-****-************'.length;
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition as any}
    >
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'row',
          alignItems: 'center',
          padding: theme.spacing(2),
          backgroundColor: theme.palette.primary.dark,
        }}
      >
        <Typography
          variant="h4"
          color="inherit"
        >{`${user.displayName}'s Profile`}</Typography>
        <IconButton
          onClick={onClose}
          style={{ alignSelf: 'flex-start', margin: theme.spacing(2) }}
        >
          <Close />
        </IconButton>
      </Box>
      <DialogContent
        style={{
          padding: theme.spacing(2),
          backgroundColor: theme.palette.primary.main,
        }}
      >
        <List>
          <ListItem>
            <ListItemText primary={'Email'} secondary={user.email} />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText
              primary={'Display Name'}
              secondary={user.displayName}
            />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText primary={'Username'} secondary={user.username} />
          </ListItem>
          <TextField
            helperText="include the dashes: ********-****-****-****-************"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setRedeemCode(event.target.value);
            }}
            error={redeemCode.length !== codeLength}
            label="Redeem Code"
            variant="filled"
            margin="normal"
            color="secondary"
            style={{ maxWidth: 800 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Redeem />
                </InputAdornment>
              ),
              endAdornment: loadingRedeem ? (
                <CircularProgress />
              ) : (
                <Button
                  disabled={redeemCode.length !== codeLength}
                  onClick={() => {
                    redeem({ code: redeemCode });
                  }}
                  color="secondary"
                >
                  Submit
                </Button>
              ),
            }}
          ></TextField>
        </List>
      </DialogContent>
      <DialogActions
        style={{
          backgroundColor: theme.palette.primary.dark,
        }}
      >
        <Button
          variant="contained"
          color="secondary"
          size="large"
          onClick={() => {
            setCookie(COOKIE_TOKEN, null);
            setMessages({ ...messages, success: 'Log out success' });
            setUser(initialUserState);
            onClose();
          }}
          startIcon={<ExitToApp />}
        >
          Log out
        </Button>
      </DialogActions>
    </Dialog>
  );
};
