export const TOPIC_CREATE_SCREWS = 10;
export const UPGRADE_SCREWS = 30;
export const MONTHLY_SCREWS = 2;
export const TOPIC_BUMP_SCREWS = 1;
export const REPLY_CREATE_SCREWS = 1;
export const REPLY_BUMP_SCREWS = 1;
export const TOPICS_PAGE_LIMIT = 20;
export const TOPIC_TITLE_MAX_LENGTH = 128;
export const TOPIC_DESCRIPTION_MAX_LENGTH = 4096;
export const REPLY_MAX_LENGTH = 512;
export const REPLY_THREAD_MESSAGE_MAX_LENGTH = 128;
