import { Box, Tooltip, useTheme } from '@mui/material';

export const ScrewCounter = (props: { count: number }) => {
  const theme = useTheme();
  return (
    <Box style={{ marginTop: theme.spacing(2) }}>
      {[...Array(Math.floor(props.count / 1000000))].map((e, i) => (
        <Tooltip key={i} title="1,000,000 screws">
          <img
            src="/donatorL5.png"
            alt="screws"
            style={{ width: 32, height: 32, alignSelf: 'center' }}
          />
        </Tooltip>
      ))}
      {[...Array(Math.floor((props.count % 1000000) / 100000))].map((e, i) => (
        <Tooltip key={i} title="100,000 screws">
          <img
            src="/donatorL4.png"
            alt="screws"
            style={{ width: 32, height: 32, alignSelf: 'center' }}
          />
        </Tooltip>
      ))}
      {[...Array(Math.floor((props.count % 100000) / 10000))].map((e, i) => (
        <Tooltip key={i} title="10,000 screws">
          <img
            src="/donatorL3.png"
            alt="screws"
            style={{ width: 32, height: 32, alignSelf: 'center' }}
          />
        </Tooltip>
      ))}
      {[...Array(Math.floor((props.count % 10000) / 1000))].map((e, i) => (
        <Tooltip key={i} title="1,000 screws">
          <img
            src="/donatorL2.png"
            alt="screws"
            style={{ width: 32, height: 32, alignSelf: 'center' }}
          />
        </Tooltip>
      ))}
      {[...Array(Math.floor((props.count % 1000) / 100))].map((e, i) => (
        <Tooltip key={i} title="100 screws">
          <img
            src="/donatorL1.png"
            alt="screws"
            style={{ width: 32, height: 32, alignSelf: 'center' }}
          />
        </Tooltip>
      ))}
      {[...Array(Math.floor((props.count % 100) / 10))].map((e, i) => (
        <Tooltip key={i} title="10 screws">
          <img
            src="/screw.ico"
            alt="screws"
            style={{ width: 32, height: 32, alignSelf: 'center' }}
          />
        </Tooltip>
      ))}
    </Box>
  );
};
