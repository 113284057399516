import { Box, CircularProgress, Typography, useTheme } from '@mui/material';
import { useContext, useEffect } from 'react';
import { useApi } from '../../api/api';
import { SnackbarContext } from '../../contexts/snackbar.context';

export const UpdatesPage = () => {
  const theme = useTheme();

  const { setMessages } = useContext(SnackbarContext);

  const {
    data: updateLogs,
    loading: updateLogsLoading,
    error: errorGetUpdateLogs,
    sendRequest: getUpdateLogs,
  } = useApi<string[]>('misc/updates', 'GET');

  useEffect(() => {
    getUpdateLogs();
  }, [getUpdateLogs]);

  useEffect(() => {
    if (updateLogs && errorGetUpdateLogs.message) {
      setMessages((m) => ({
        ...m,
        error: errorGetUpdateLogs.message,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateLogs, errorGetUpdateLogs, setMessages]);

  if (updateLogsLoading) return <CircularProgress />;

  return (
    <Box style={{ width: '100%', height: '100%' }}>
      {updateLogs?.map((ul, ind) => {
        return (
          <Box key={ind}>
            {ind % 2 === 0 && (
              <Typography
                variant="h5"
                style={{
                  color: theme.palette.text.primary,
                  padding: theme.spacing(2),
                }}
              >
                {ul}
              </Typography>
            )}
            {ind % 2 === 1 && (
              <pre
                style={{
                  color: theme.palette.text.primary,
                  padding: theme.spacing(2),
                  fontFamily: 'Roboto, sans-serif',
                }}
              >
                {ul}
              </pre>
            )}
          </Box>
        );
      })}
    </Box>
  );
};

export default UpdatesPage;
