import { useContext, useEffect } from 'react';
import { createContext } from 'react';
import { useCookies } from 'react-cookie';
import { useApi } from '../api/api';
import { SnackbarContext } from './snackbar.context';
import { UserContext, UserState } from './user.context';

export const COOKIE_TOKEN = 'token';
export const COOKIE_MUTED = 'muted';

export const InitialLoaderContext = createContext({});

export const InitialLoaderProvider = (props: { children: JSX.Element }) => {
  const { user, setUser } = useContext(UserContext);
  const [cookies, setCookie] = useCookies([COOKIE_TOKEN]);
  const {
    data: meData,
    error: errorMessage,
    sendRequest: getMe,
  } = useApi<UserState>('users/me', 'GET');
  const { setMessages } = useContext(SnackbarContext);

  useEffect(() => {
    cookies.token && setUser({ ...user, token: cookies.token });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user.token && !user.username) {
      getMe();
    }
  }, [getMe, user]);

  useEffect(() => {
    if (errorMessage.code)
      setMessages((m) => ({
        ...m,
        error: errorMessage.message,
      }));
    else meData && setUser((u) => ({ ...u, ...meData }));
  }, [errorMessage, meData, setMessages, setUser]);

  useEffect(() => {
    let d = new Date();
    d.setTime(d.getTime() + 1000 * 60 * 60); // 1 hour
    setCookie(COOKIE_TOKEN, user.token, {
      expires: d,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.token]);

  return (
    <InitialLoaderContext.Provider value={{}}>
      {props.children}
    </InitialLoaderContext.Provider>
  );
};
