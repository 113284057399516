import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionSummary,
  Box,
  Button,
  Divider,
  styled,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { format } from 'date-fns';
import { now } from 'lodash';
import { Fragment, useContext, useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { useApi } from '../../../api/api';
import { REPLY_BUMP_SCREWS, REPLY_MAX_LENGTH } from '../../../constants';
import { SnackbarContext } from '../../../contexts/snackbar.context';
import { UserContext } from '../../../contexts/user.context';
import { ButtonHeightCircularProgress } from '../../ButtonHeightCircularProgress';
import { DecoratedDisplayName } from '../../DecoratedDisplayName';
import { NumberText } from '../../NumberText';
import { TypographyCursor } from '../../TypographyCursor';
import { Reply, Topic, UserMap } from './DevelopmentPage';
import { DevelopmentThreadMessage } from './DevelopmentThreadMessage';

export const DevelopmentReply = (props: {
  topic: Topic;
  reply: Reply;
  userMap?: UserMap;
  refresh: () => void;
  onClose?: () => void;
  forum: boolean;
}) => {
  const { topic, reply, refresh, userMap, onClose, forum } = props;
  const { user } = useContext(UserContext);
  const [state, setState] = useState<'new' | 'amend' | 'overwrite' | 'default'>(
    reply.message === '' ? 'new' : 'default'
  );
  const isAdmin = user.roles.includes('ADMIN');
  const [accordionOpen, setAccordionOpen] = useState(false);
  const theme = useTheme();
  const { setMessages } = useContext(SnackbarContext);
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [textfieldMessage, setTextfieldMessage] = useState('');
  const [newThreadMessage, setNewThreadMessage] = useState(false);
  const editAmendDividerText = `\n\n---\n\n##### Edit ${format(
    now(),
    'Pp'
  )}:\n\n`;

  useEffect(() => {
    if (newThreadMessage) setAccordionOpen(true);
  }, [newThreadMessage]);

  const {
    data: bumpReplyResponse,
    loading: loadingBumpReply,
    error: errorBumpReply,
    sendRequest: bumpReply,
  } = useApi('topic/bump-reply', 'POST');

  const {
    data: createReplyResponse,
    loading: loadingCreateReply,
    error: errorCreateReply,
    sendRequest: createReply,
  } = useApi('topic/reply', 'POST');

  const {
    data: overwriteReplyResponse,
    loading: loadingOverwriteReply,
    error: errorOverwriteReply,
    sendRequest: overwriteReply,
  } = useApi('topic/reply', 'PATCH');

  const {
    data: amendReplyResponse,
    loading: loadingAmendReply,
    error: errorAmendReply,
    sendRequest: amendReply,
  } = useApi('topic/amend/reply', 'PATCH');

  const {
    data: deleteReplyResponse,
    loading: loadingDeleteReply,
    error: errorDeleteReply,
    sendRequest: deleteReply,
  } = useApi('topic/reply', 'DELETE');

  useEffect(() => {
    if (errorCreateReply.message) {
      setMessages((m) => ({
        ...m,
        error: errorCreateReply.message,
      }));
    } else if (createReplyResponse) {
      setMessages((m) => ({
        ...m,
        success: 'Reply created',
      }));
      onClose && onClose();
      refresh();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createReplyResponse, errorCreateReply, setMessages]);

  useEffect(() => {
    if (bumpReplyResponse && !errorBumpReply.message) {
      setMessages((m) => ({ ...m, success: 'Success' }));
      refresh();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bumpReplyResponse, errorBumpReply, setMessages]);

  useEffect(() => {
    if (overwriteReplyResponse && !errorOverwriteReply.message) {
      setMessages((m) => ({ ...m, success: 'Success' }));
      setState('default');
      refresh();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [overwriteReplyResponse, errorOverwriteReply, setMessages]);

  useEffect(() => {
    if (amendReplyResponse && !errorAmendReply.message) {
      setMessages((m) => ({ ...m, success: 'Success' }));
      setState('default');
      refresh();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amendReplyResponse, errorAmendReply, setMessages]);

  useEffect(() => {
    if (deleteReplyResponse && !errorDeleteReply.message) {
      setMessages((m) => ({ ...m, success: 'Success' }));
      refresh();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteReplyResponse, errorDeleteReply, setMessages]);

  const ResponsiveContainer = smallScreen
    ? VerticalContainer
    : HorizontalContainer;

  return (
    <Box>
      <Box
        style={{
          padding: theme.spacing(1),
        }}
      >
        <ResponsiveContainer>
          <DecoratedDisplayName
            displayName={reply.creatorName}
            userMap={userMap}
          />
          <HorizontalContainer>
            <NumberText
              style={{
                color: theme.palette.secondary.contrastText,
                marginLeft: theme.spacing(2),
              }}
              amount={reply.screws}
              prefix="Screws: "
            />
            <ResponsiveContainer>
              <HorizontalContainer>
                {state === 'default' && (
                  <Button
                    disabled={
                      !forum &&
                      (loadingBumpReply || user.screws < REPLY_BUMP_SCREWS)
                    }
                    style={{
                      marginLeft: theme.spacing(2),
                      marginBottom: theme.spacing(1),
                      marginTop: theme.spacing(1),
                    }}
                    color="secondary"
                    variant="contained"
                    onClick={() => {
                      bumpReply({
                        message: reply.message,
                        title: topic.title,
                        forum,
                      });
                    }}
                  >
                    {loadingBumpReply ? (
                      <ButtonHeightCircularProgress />
                    ) : forum ? (
                      'Bump'
                    ) : (
                      `Bump (${REPLY_BUMP_SCREWS} screw)`
                    )}
                  </Button>
                )}
                {reply?.thread?.length === 0 && state === 'default' && (
                  <Button
                    style={{
                      marginLeft: theme.spacing(2),
                      marginBottom: theme.spacing(1),
                      marginTop: theme.spacing(1),
                    }}
                    color="secondary"
                    variant="contained"
                    onClick={() => setNewThreadMessage(!newThreadMessage)}
                  >
                    {newThreadMessage ? 'Cancel' : 'Start thread'}
                  </Button>
                )}
              </HorizontalContainer>
              <HorizontalContainer>
                {!(!isAdmin && reply.creatorName !== user.displayName) && (
                  <Button
                    style={{
                      marginLeft: theme.spacing(2),
                      marginBottom: theme.spacing(1),
                      marginTop: theme.spacing(1),
                    }}
                    color="secondary"
                    variant="contained"
                    onClick={() => {
                      if (state === 'overwrite')
                        overwriteReply({
                          title: topic.title,
                          message: reply.message,
                          updateMessage: textfieldMessage,
                          forum,
                        });
                      else if (state === 'amend')
                        amendReply({
                          title: topic.title,
                          message: reply.message,
                          updateMessage: textfieldMessage,
                          forum,
                        });
                      else if (state === 'new')
                        createReply({
                          title: topic.title,
                          message: textfieldMessage,
                          forum,
                        });
                      else if (state === 'default') {
                        if (isAdmin) {
                          setState('overwrite');
                          setTextfieldMessage(reply.message);
                        } else {
                          setState('amend');
                          setTextfieldMessage('');
                        }
                      }
                    }}
                    disabled={state !== 'default' && !textfieldMessage}
                  >
                    {loadingCreateReply ||
                    loadingOverwriteReply ||
                    loadingAmendReply ? (
                      <ButtonHeightCircularProgress />
                    ) : state === 'default' ? (
                      isAdmin ? (
                        'Edit'
                      ) : (
                        'Amend'
                      )
                    ) : (
                      'save'
                    )}
                  </Button>
                )}
                {state !== 'default' && (
                  <Button
                    style={{
                      marginLeft: theme.spacing(2),
                      marginBottom: theme.spacing(1),
                      marginTop: theme.spacing(1),
                    }}
                    color="secondary"
                    variant="contained"
                    onClick={() => {
                      if (state === 'new') {
                        onClose && onClose();
                      } else {
                        setState('default');
                        setTextfieldMessage('');
                      }
                    }}
                  >
                    Cancel
                  </Button>
                )}

                {isAdmin && (
                  <Button
                    disabled={loadingDeleteReply}
                    style={{
                      marginLeft: theme.spacing(2),
                      marginRight: theme.spacing(1),
                      marginBottom: theme.spacing(1),
                      marginTop: theme.spacing(1),
                      backgroundColor: theme.palette.error.main,
                    }}
                    variant="contained"
                    onClick={() => {
                      if (
                        window.confirm(
                          'REALLY REALLY DELETE THIS REPLY? ALL THREAD MESSAGES ASSOCIATED WITH THIS REPLY WILL ALSO BE DELETED!!!'
                        )
                      )
                        if (window.confirm('ARE YOU SURE?!?!?!'))
                          deleteReply({
                            title: topic.title,
                            message: reply.message,
                            forum,
                          });
                    }}
                  >
                    {loadingDeleteReply ? (
                      <ButtonHeightCircularProgress />
                    ) : (
                      'DELETE'
                    )}
                  </Button>
                )}
              </HorizontalContainer>
            </ResponsiveContainer>
          </HorizontalContainer>
        </ResponsiveContainer>

        {state !== 'default' && (
          <Fragment>
            <Description>
              <TextField
                autoFocus
                placeholder={state === 'amend' ? 'amend' : 'Description'}
                defaultValue={reply.message}
                error={
                  !textfieldMessage ||
                  textfieldMessage.length >= REPLY_MAX_LENGTH
                }
                value={textfieldMessage}
                onChange={(e) => setTextfieldMessage(e.target.value)}
                multiline
                helperText={`Must be unique and not empty. You can amend this later. Markdown formatted (use 2 enter keys per new line). limit: (${
                  state === 'amend'
                    ? textfieldMessage.length +
                      editAmendDividerText.length +
                      3 + // in case date becomes longer when submitted
                      reply.message.length
                    : textfieldMessage.length
                }/${REPLY_MAX_LENGTH})`}
                inputProps={{ maxLength: REPLY_MAX_LENGTH }}
                fullWidth
              />
            </Description>
            <Typography
              variant="h6"
              style={{ color: theme.palette.secondary.contrastText }}
            >
              Preview
            </Typography>
          </Fragment>
        )}
        <Description>
          <ReactMarkdown>
            {state === 'amend'
              ? reply.message + editAmendDividerText + textfieldMessage
              : state === 'default'
              ? reply.message
              : textfieldMessage}
          </ReactMarkdown>
        </Description>
        {(reply?.thread?.length !== 0 || newThreadMessage) && (
          <Accordion
            expanded={accordionOpen}
            onChange={(e, expanded) => setAccordionOpen(expanded)}
            style={{
              backgroundColor: theme.palette.primary.dark,
              margin: theme.spacing(3),
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              style={{
                cursor: `url('/pointer.png'), auto`,
                backgroundColor: theme.palette.primary.dark,
              }}
            >
              <TypographyCursor>
                {accordionOpen ? 'Hide Reply Thread' : 'Show Reply Thread'}
              </TypographyCursor>
            </AccordionSummary>
            <Box
              style={{
                paddingBottom: theme.spacing(2),
                backgroundColor: theme.palette.primary.dark,
              }}
            >
              <Box
                style={{
                  backgroundColor: theme.palette.primary.main,
                  marginLeft: theme.spacing(3),
                  marginRight: theme.spacing(3),
                  paddingBottom: theme.spacing(2),
                }}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    setNewThreadMessage(true);
                  }}
                  style={{
                    margin: theme.spacing(2),
                  }}
                >
                  Reply
                </Button>
                {newThreadMessage && (
                  <DevelopmentThreadMessage
                    forum={forum}
                    userMap={userMap}
                    topic={topic}
                    reply={reply}
                    threadMessage={{
                      message: '',
                      creatorName: user.displayName,
                    }}
                    refresh={refresh}
                    threadIndex={0}
                    onClose={() => setNewThreadMessage(false)}
                  />
                )}
                {reply?.thread.map((thread, threadIndex) => (
                  <DevelopmentThreadMessage
                    forum={forum}
                    key={threadIndex}
                    userMap={userMap}
                    topic={topic}
                    reply={reply}
                    threadMessage={thread}
                    refresh={refresh}
                    threadIndex={threadIndex}
                  />
                ))}
              </Box>
            </Box>
          </Accordion>
        )}
      </Box>
      {topic?.replies?.length > 0 &&
        reply.message !== topic?.replies[topic?.replies?.length - 1].message &&
        reply?.thread.length === 0 && (
          <Divider
            style={{
              backgroundColor: theme.palette.secondary.dark,
            }}
          />
        )}
    </Box>
  );
};

const HorizontalContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const VerticalContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}));

const Description = styled(Box)(({ theme }) => ({
  cursor: `url('/blinker.png') 8 10, text`,
  padding: theme.spacing(1),
  margin: theme.spacing(1),
  maxHeight: 500,
  resize: 'both',
  overflow: 'auto',
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.primary.light,
  fontFamily: 'Roboto, sans-serif',
}));
