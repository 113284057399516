import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';

export const WorldsPage = () => {
  // const styles = getStyles();
  const theme = useTheme();
  return (
    <Box>
      <Typography
        variant="h3"
        style={{ color: theme.palette.text.primary, padding: theme.spacing(2) }}
      >
        Worlds page
      </Typography>
      <Typography
        style={{ color: theme.palette.text.primary, padding: theme.spacing(2) }}
      >
        This will be a grid of treading creations, world files, or seeds that
        users can share
      </Typography>
    </Box>
  );
};

export default WorldsPage;
