import { TechNode } from './TechTree';

export const encodeXML = (tech: TechNode[]) => {
  return `
<?xml version="1.0" encoding="utf-8"?>
<!--note that unlocks refer to entity id not tech node, while prerequisites refer to tech nodes-->
<!-- required: id type unlocked name description -->
<tech>
              ${tech
                .map((node) => {
                  return `
  <node id="${node.id}" type="${node.type}" level="${node.level}" unlocked="${
                    node.unlocked === true ? 'true' : 'false'
                  }" unlocks="${formatNumberArray(node.unlocks).join(
                    ','
                  )}" prerequisites="${node.prerequisites}" x="${
                    node.x
                  }" y="${-node.y}">
    <name>
      ${node.name}
    </name>
    <description>
      ${node.description}
    </description>
    <explanation>
      ${node.explanation}
    </explanation>
    <question>
      ${node.question}
    </question>
    <inputs>
      ${node.inputs}
    </inputs>
    <answers>
      ${node.answers}
    </answers>
  </node>`;
                })
                .join('')}
</tech>
                `;
};

export const formatQuestion = (
  question: string = '',
  inputs: string = '',
  questionPreset = 0
) => {
  const parsedInputs = inputs.split(';').map((s) => s.split(','));
  for (let i = 0; i < inputs.length; i++) {
    question = question.replace(
      `\${${i}}`,
      parsedInputs?.[i]?.[questionPreset] ?? ''
    );
  }
  return question;
};

export const formatAnswer = (
  answers: string = '',
  questionPreset: number = 0
) => {
  return answers.split(',')?.[questionPreset];
};

export const formatNumberArray = (arr: string) => {
  return arr
    .split(',')
    .map((n) => Number(n))
    .filter((n) => !isNaN(n));
};

export enum TechNodeLevel {
  STONE,
  BRONZE,
  IRON,
  RENAISSANCE,
  INDUSTRIAL,
  ATOMIC,
  SPACE,
  CUTTING_EDGE,
  FUTURE,
}

export const getNodeName = (level: TechNodeLevel) => {
  switch (level) {
    case TechNodeLevel.STONE:
      return 'Stone';
    case TechNodeLevel.BRONZE:
      return 'Bronze';
    case TechNodeLevel.IRON:
      return 'Iron';
    case TechNodeLevel.RENAISSANCE:
      return 'Renaissance';
    case TechNodeLevel.INDUSTRIAL:
      return 'Industrial';
    case TechNodeLevel.ATOMIC:
      return 'Atomic';
    case TechNodeLevel.SPACE:
      return 'Space';
    case TechNodeLevel.CUTTING_EDGE:
      return 'Cutting Edge';
    case TechNodeLevel.FUTURE:
      return 'Future';
    default:
      return '';
  }
};

export const getNodeColor = (level: TechNodeLevel) => {
  let color = [0, 0, 0, 1.0];
  switch (level) {
    case TechNodeLevel.STONE:
      color = [120, 120, 120, 1.0];
      break;
    case TechNodeLevel.BRONZE:
      color = [163, 117, 18, 1.0];
      break;
    case TechNodeLevel.IRON:
      color = [168, 154, 140, 1.0];
      break;
    case TechNodeLevel.RENAISSANCE:
      color = [105, 179, 98, 1.0];
      break;
    case TechNodeLevel.INDUSTRIAL:
      color = [92, 126, 158, 1.0];
      break;
    case TechNodeLevel.ATOMIC:
      color = [142, 92, 158, 1.0];
      break;
    case TechNodeLevel.SPACE:
      color = [194, 68, 37, 1.0];
      break;
    case TechNodeLevel.CUTTING_EDGE:
      color = [168, 39, 155, 1.0];
      break;
    case TechNodeLevel.FUTURE:
      color = [255, 5, 180, 1.0];
      break;
  }
  return `rgba(${color[0]}, ${color[1]}, ${color[2]}, ${color[3]})`;
};
