import { Dialog, IconButton, Slide, Typography, useTheme } from '@mui/material';
import { Close } from '@mui/icons-material';
import { forwardRef } from 'react';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...(props as any)} />;
});

export const SupportDialog = (props: {
  open: boolean;
  onClose: () => void;
}) => {
  const { open, onClose } = props;
  const theme = useTheme();
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition as any}
    >
      <IconButton
        style={{ alignSelf: 'flex-start', margin: theme.spacing(1) }}
        onClick={onClose}
      >
        <Close />
      </IconButton>
      <Typography style={{ margin: theme.spacing(2) }}>
        Please email support@axolotlstudios.com for any questions or concerns
      </Typography>
    </Dialog>
  );
};
