import {
  Box,
  Button,
  Divider,
  styled,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Fragment, useContext, useEffect, useState } from 'react';
import { useApi } from '../../../api/api';
import { REPLY_THREAD_MESSAGE_MAX_LENGTH } from '../../../constants';
import { SnackbarContext } from '../../../contexts/snackbar.context';
import { UserContext } from '../../../contexts/user.context';
import { ButtonHeightCircularProgress } from '../../ButtonHeightCircularProgress';
import { DecoratedDisplayName } from '../../DecoratedDisplayName';
import { Reply, ReplyThreadMessage, Topic, UserMap } from './DevelopmentPage';

export const DevelopmentThreadMessage = (props: {
  topic: Topic;
  reply: Reply;
  threadMessage: ReplyThreadMessage;
  userMap?: UserMap;
  refresh: () => void;
  onClose?: () => void;
  threadIndex: number;
  forum: boolean;
}) => {
  const {
    topic,
    reply,
    refresh,
    userMap,
    threadIndex,
    threadMessage,
    onClose,
    forum,
  } = props;
  const { user } = useContext(UserContext);
  const isAdmin = user.roles.includes('ADMIN');

  const theme = useTheme();
  // const styles = getStyles();
  const { setMessages } = useContext(SnackbarContext);
  const [textfieldMessage, setTextfieldMessage] = useState('');
  const [state, setState] = useState<'new' | 'amend' | 'overwrite' | 'default'>(
    threadMessage.message === '' ? 'new' : 'default'
  );
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    data: createReplyThreadMessageResponse,
    loading: loadingCreateReplyThreadMessage,
    error: errorCreateReplyThreadMessage,
    sendRequest: createReplyThreadMessage,
  } = useApi('topic/reply-thread', 'POST');

  const {
    data: amendReplyThreadMessageResponse,
    loading: loadingAmendReplyThreadMessage,
    error: errorAmendReplyThreadMessage,
    sendRequest: amendReplyThreadMessage,
  } = useApi('topic/amend/reply-thread', 'PATCH');

  const {
    data: overwriteReplyThreadMessageResponse,
    loading: loadingOverwriteReplyThreadMessage,
    error: errorOverwriteReplyThreadMessage,
    sendRequest: overwriteReplyThreadMessage,
  } = useApi('topic/reply-thread', 'PATCH');

  const {
    data: deleteReplyThreadMessageResponse,
    loading: loadingDeleteReplyThreadMessage,
    error: errorDeleteReplyThreadMessage,
    sendRequest: deleteReplyThreadMessage,
  } = useApi('topic/reply-thread', 'DELETE');

  useEffect(() => {
    if (errorCreateReplyThreadMessage.message) {
      setMessages((m) => ({
        ...m,
        error: errorCreateReplyThreadMessage.message,
      }));
    } else if (createReplyThreadMessageResponse) {
      setMessages((m) => ({
        ...m,
        success: 'Thread message created',
      }));
      refresh();
      if (state === 'new') onClose && onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    createReplyThreadMessageResponse,
    errorCreateReplyThreadMessage,
    setMessages,
  ]);

  useEffect(() => {
    if (
      overwriteReplyThreadMessageResponse &&
      !errorOverwriteReplyThreadMessage.message
    ) {
      setMessages((m) => ({ ...m, success: 'Success' }));
      setState('default');
      refresh();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    overwriteReplyThreadMessageResponse,
    errorOverwriteReplyThreadMessage,
    setMessages,
  ]);

  useEffect(() => {
    if (
      amendReplyThreadMessageResponse &&
      !errorAmendReplyThreadMessage.message
    ) {
      setMessages((m) => ({ ...m, success: 'Success' }));
      setState('default');
      refresh();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    amendReplyThreadMessageResponse,
    errorAmendReplyThreadMessage,
    setMessages,
  ]);

  useEffect(() => {
    if (
      deleteReplyThreadMessageResponse &&
      !errorDeleteReplyThreadMessage.message
    ) {
      setMessages((m) => ({ ...m, success: 'Success' }));
      refresh();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    deleteReplyThreadMessageResponse,
    errorDeleteReplyThreadMessage,
    setMessages,
  ]);

  const ResponsiveContainer = smallScreen
    ? VerticalContainer
    : HorizontalContainer;

  return (
    <Box>
      <Box
        style={{
          padding: theme.spacing(2),
        }}
      >
        <ResponsiveContainer>
          <DecoratedDisplayName
            displayName={threadMessage.creatorName}
            userMap={userMap}
          />
          <HorizontalContainer>
            {!(!isAdmin && threadMessage.creatorName !== user.displayName) && (
              <Button
                style={{
                  marginLeft: theme.spacing(2),
                }}
                color="secondary"
                variant="contained"
                onClick={() => {
                  if (state === 'overwrite')
                    overwriteReplyThreadMessage({
                      title: topic.title,
                      message: reply.message,
                      replyThreadMessage: threadMessage.message,
                      creatorName: threadMessage.creatorName,
                      updateReplyThreadMessage: textfieldMessage,
                      forum,
                    });
                  else if (state === 'amend')
                    amendReplyThreadMessage({
                      title: topic.title,
                      message: reply.message,
                      replyThreadMessage: threadMessage.message,
                      updateReplyThreadMessage: textfieldMessage,
                      forum,
                    });
                  else if (state === 'new')
                    createReplyThreadMessage({
                      title: topic.title,
                      message: reply.message,
                      replyThreadMessage: textfieldMessage,
                      forum,
                    });
                  else if (state === 'default') {
                    if (isAdmin) {
                      setState('overwrite');
                      setTextfieldMessage(threadMessage.message);
                    } else {
                      setState('amend');
                      setTextfieldMessage('');
                    }
                  }
                }}
                disabled={state !== 'default' && !textfieldMessage}
              >
                {loadingCreateReplyThreadMessage ||
                loadingOverwriteReplyThreadMessage ||
                loadingAmendReplyThreadMessage ? (
                  <ButtonHeightCircularProgress />
                ) : state === 'default' ? (
                  isAdmin ? (
                    'Edit'
                  ) : (
                    'Amend'
                  )
                ) : (
                  'save'
                )}
              </Button>
            )}
            {state !== 'default' && (
              <Button
                style={{
                  marginLeft: theme.spacing(2),
                }}
                color="secondary"
                variant="contained"
                onClick={() => {
                  if (state === 'new') {
                    onClose && onClose();
                  } else {
                    setState('default');
                    setTextfieldMessage('');
                  }
                }}
              >
                Cancel
              </Button>
            )}
            {isAdmin && (
              <Button
                disabled={loadingDeleteReplyThreadMessage}
                style={{
                  marginLeft: theme.spacing(2),
                  marginRight: theme.spacing(1),
                  backgroundColor: theme.palette.error.main,
                }}
                variant="contained"
                onClick={() => {
                  if (
                    window.confirm(
                      'REALLY REALLY DELETE THIS THREAD MESSAGE? ALL THREAD MESSAGES WITH THE SAME MESSAGE OF THIS USER WILL BE DELETED!!!'
                    )
                  )
                    deleteReplyThreadMessage({
                      title: topic.title,
                      message: reply.message,
                      displayName: threadMessage.creatorName,
                      replyThreadMessage: threadMessage.message,
                      forum,
                    });
                }}
              >
                {loadingDeleteReplyThreadMessage ? (
                  <ButtonHeightCircularProgress />
                ) : (
                  'DELETE'
                )}
              </Button>
            )}
          </HorizontalContainer>
        </ResponsiveContainer>
        {state !== 'default' && (
          <Fragment>
            <TextField
              autoFocus
              placeholder={state === 'amend' ? 'amend' : 'Thread Message'}
              defaultValue={threadMessage.message}
              error={
                !textfieldMessage ||
                textfieldMessage.length >= REPLY_THREAD_MESSAGE_MAX_LENGTH
              }
              value={textfieldMessage}
              onChange={(e) => setTextfieldMessage(e.target.value)}
              multiline
              helperText={`Must not be empty. You can amend this later. limit: (${
                state === 'amend'
                  ? textfieldMessage.length +
                    ' Edit: '.length +
                    threadMessage.message.length
                  : textfieldMessage.length
              }/${REPLY_THREAD_MESSAGE_MAX_LENGTH})`}
              inputProps={{ maxLength: REPLY_THREAD_MESSAGE_MAX_LENGTH }}
              fullWidth
            />
            <Typography
              variant="h6"
              style={{ color: theme.palette.secondary.contrastText }}
            >
              Preview
            </Typography>
          </Fragment>
        )}
        <Typography
          style={{
            backgroundColor: theme.palette.primary.main,
            margin: theme.spacing(2),
            marginTop: 0,
            marginBottom: 0,
            color: theme.palette.secondary.contrastText,
          }}
          key={threadIndex}
        >
          {state === 'amend'
            ? threadMessage.message + ' Edit: ' + textfieldMessage
            : state === 'default'
            ? threadMessage.message
            : textfieldMessage}
        </Typography>
      </Box>
      {threadIndex !== reply?.thread.length - 1 && (
        <Divider
          style={{
            backgroundColor: theme.palette.secondary.dark,
          }}
        />
      )}
    </Box>
  );
};

const HorizontalContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const VerticalContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}));
