import { Typography } from '@mui/material';
import { TypographyCursor } from './TypographyCursor';

export const NumberText = (props: {
  style?: any;
  amount: number;
  prefix?: string;
  pointerCursor?: boolean;
}) => {
  let string =
    props.prefix +
    props.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  if (props.pointerCursor)
    return <TypographyCursor style={props.style}>{string}</TypographyCursor>;
  else return <Typography style={props.style}>{string}</Typography>;
};
