import { Box, Tooltip, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/system';
import { Role } from '../contexts/user.context';

export type DecoratedDisplayNameProps = {
  displayName: string;
  userMap: { [key: string]: Role[] } | undefined;
};

export const DecoratedDisplayName = (props: DecoratedDisplayNameProps) => {
  const { displayName, userMap } = props;
  const theme = useTheme();
  const roles = userMap?.[displayName] ?? [];
  return (
    <HorizontalContainer>
      <Typography
        variant="body1"
        style={{
          color: roles.includes('ADMIN')
            ? '#ff1f1f'
            : roles.includes('DEVELOPER')
            ? '#3c26ff'
            : roles.includes('ENDORSED')
            ? '#711fff'
            : roles.includes('DONATORL1')
            ? '#26ff79'
            : roles.includes('DONATORL2')
            ? '#26f8ff'
            : roles.includes('DONATORL3')
            ? '#ffb726'
            : theme.palette.secondary.dark,
        }}
      >
        {displayName}
      </Typography>
      {roles.includes('ADMIN') && (
        <Tooltip title="Axolotl Studios Admin">
          <RoleIcon src={'/admin.png'} alt="admin" />
        </Tooltip>
      )}
      {roles.includes('DEVELOPER') && (
        <Tooltip title="Axolotl Studios Developer">
          <RoleIcon src={'/developer.png'} alt="developer" />
        </Tooltip>
      )}
      {roles.includes('ENDORSED') && (
        <Tooltip title="Endorsed Community Member">
          <RoleIcon src={'/endorsed.png'} alt="endorsed" />
        </Tooltip>
      )}
      {roles.includes('DONATORL3') && (
        <Tooltip title="Level 3 Donator">
          <RoleIcon src={'/donatorL3.png'} alt="donator L3" />
        </Tooltip>
      )}
      {roles.includes('DONATORL2') && (
        <Tooltip title="Level 2 Donator">
          <RoleIcon src={'/donatorL2.png'} alt="donator L2" />
        </Tooltip>
      )}
      {roles.includes('DONATORL1') && (
        <Tooltip title="Level 1 Donator">
          <RoleIcon src={'/donatorL1.png'} alt="donator L1" />
        </Tooltip>
      )}
    </HorizontalContainer>
  );
};

const HorizontalContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  margin: theme.spacing(1),
  alignItems: 'center',
}));
const RoleIcon = styled('img')(({ theme }) => ({
  width: 26,
  height: 26,
  marginLeft: 6,
  alignSelf: 'center',
}));
