import React from 'react';
import { Typography, Button, Box } from '@mui/material';
import { useHistory } from 'react-router-dom';

export const SuccessScreen = () => {
  const history = useHistory();
  return (
    <Box>
      <Typography variant="h4">Payment success!</Typography>
      <Typography variant="h6">
        Your account has been upgrade, click here to go to the portal to
        download your game!
      </Typography>
      <Button
        onClick={() => {
          history.push('/');
        }}
      >
        Go to Portal
      </Button>
    </Box>
  );
};
