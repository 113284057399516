import {
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  useTheme,
} from '@mui/material';
import { AccountCircle, Close, Mail } from '@mui/icons-material';
import React, { useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { UserContext } from '../contexts/user.context';
import { SupportDialog } from './dialogs/SupportDialog';
import { TypographyCursor } from './TypographyCursor';

export enum SidebarPages {
  SUPPORT = 'support',
}

export const Sidebar = (props: { open: boolean; onClose: () => void }) => {
  const { open, onClose } = props;
  const { user } = useContext(UserContext);
  const history = useHistory();
  const { pageParam } = useParams<{
    pageParam: string | undefined;
    innerPageParam: string | undefined;
  }>();
  const theme = useTheme();

  return (
    <Drawer anchor="left" open={open} onClose={onClose}>
      <Box
        style={{ backgroundColor: theme.palette.primary.dark, height: '100%' }}
      >
        <IconButton
          style={{ alignSelf: 'flex-end', margin: theme.spacing(1) }}
          onClick={onClose}
        >
          <Close />
        </IconButton>
        <List
          style={{ width: 240, backgroundColor: theme.palette.primary.main }}
        >
          <ListItem button>
            <ListItemIcon>
              <AccountCircle />
            </ListItemIcon>
            <TypographyCursor>{user.displayName}</TypographyCursor>
          </ListItem>
          <Divider />
          <ListItem
            button
            onClick={() => {
              history.push(`/${SidebarPages.SUPPORT}`);
            }}
          >
            <ListItemIcon>
              <Mail />
            </ListItemIcon>
            <TypographyCursor>Support</TypographyCursor>
          </ListItem>
        </List>
        <SupportDialog
          open={pageParam === SidebarPages.SUPPORT}
          onClose={() => {
            history.goBack();
          }}
        />
      </Box>
    </Drawer>
  );
};
