import axios from 'axios';
import { useCallback, useContext, useState } from 'react';
import { initialUserState, UserContext } from '../contexts/user.context';

export const apiUrl =
  // process.env.NODE_ENV === 'development'
  //   ? 'http://localhost:3003/'
  //   :
  'https://api.axolotlstudios.com/';

export enum HttpStatusCode {
  CONTINUE = 100,
  SWITCHING_PROTOCOLS = 101,
  PROCESSING = 102,
  OK = 200,
  CREATED = 201,
  ACCEPTED = 202,
  NON_AUTHORITATIVE_INFORMATION = 203,
  NO_CONTENT = 204,
  RESET_CONTENT = 205,
  PARTIAL_CONTENT = 206,
  MULTI_STATUS = 207,
  ALREADY_REPORTED = 208,
  IM_USED = 226,
  MULTIPLE_CHOICES = 300,
  MOVED_PERMANENTLY = 301,
  FOUND = 302,
  SEE_OTHER = 303,
  NOT_MODIFIED = 304,
  USE_PROXY = 305,
  SWITCH_PROXY = 306,
  TEMPORARY_REDIRECT = 307,
  PERMANENT_REDIRECT = 308,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  PAYMENT_REQUIRED = 402,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  METHOD_NOT_ALLOWED = 405,
  NOT_ACCEPTABLE = 406,
  PROXY_AUTHENTICATION_REQUIRED = 407,
  REQUEST_TIMEOUT = 408,
  CONFLICT = 409,
  GONE = 410,
  LENGTH_REQUIRED = 411,
  PRECONDITION_FAILED = 412,
  PAYLOAD_TOO_LARGE = 413,
  URI_TOO_LONG = 414,
  UNSUPPORTED_MEDIA_TYPE = 415,
  RANGE_NOT_SATISFIABLE = 416,
  EXPECTATION_FAILED = 417,
  I_AM_A_TEAPOT = 418,
  MISDIRECTED_REQUEST = 421,
  UNPROCESSABLE_ENTITY = 422,
  LOCKED = 423,
  FAILED_DEPENDENCY = 424,
  UPGRADE_REQUIRED = 426,
  PRECONDITION_REQUIRED = 428,
  TOO_MANY_REQUESTS = 429,
  REQUEST_HEADER_FIELDS_TOO_LARGE = 431,
  UNAVAILABLE_FOR_LEGAL_REASONS = 451,
  INTERNAL_SERVER_ERROR = 500,
  NOT_IMPLEMENTED = 501,
  BAD_GATEWAY = 502,
  SERVICE_UNAVAILABLE = 503,
  GATEWAY_TIMEOUT = 504,
  HTTP_VERSION_NOT_SUPPORTED = 505,
  VARIANT_ALSO_NEGOTIATES = 506,
  INSUFFICIENT_STORAGE = 507,
  LOOP_DETECTED = 508,
  NOT_EXTENDED = 510,
  NETWORK_AUTHENTICATION_REQUIRED = 511,
}

export const useApi = <T>(
  url: string,
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH'
) => {
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState<{
    message: string;
    code: number;
  }>({ message: '', code: 0 });
  const [loading, setLoading] = useState<boolean>(false);
  const { user, setUser } = useContext(UserContext);
  const sendRequest = useCallback(
    async (
      payload?: Object,
      queryParams?: { [key: string]: string | number },
      token?: string
    ): Promise<void> => {
      const headers = {
        ...(user.token || token
          ? { Authorization: 'Bearer ' + (user.token || token) }
          : {}),
      };
      let query = '';
      if (queryParams) {
        query =
          '?' +
          Object.entries(queryParams)
            .map((keyVal) => `${keyVal[0]}=${keyVal[1]}`)
            .join('&');
      }
      setLoading(true);
      clearError();
      try {
        switch (method) {
          case 'GET': {
            const response = await axios.get(url + query, {
              baseURL: apiUrl,
              method,
              headers,
            });
            setData(response.data || { default: 'success' });
            break;
          }
          case 'POST': {
            const response = await axios.post(url + query, payload, {
              baseURL: apiUrl,
              method,
              headers,
            });
            console.log('api got', response);
            setData(response.data || { default: 'success' });
            break;
          }
          case 'PUT': {
            const response = await axios.put(url + query, payload, {
              baseURL: apiUrl,
              method,
              headers,
            });
            setData(response.data || { default: 'success' });
            break;
          }
          case 'DELETE': {
            const response = await axios.delete(url + query, {
              baseURL: apiUrl,
              data: payload,
              method,
              headers,
            });
            setData(response.data || { default: 'success' });
            break;
          }
          case 'PATCH': {
            const response = await axios.patch(url + query, payload, {
              baseURL: apiUrl,
              method,
              headers,
            });
            setData(response.data || { default: 'success' });
            break;
          }
        }
        setLoading(false);
      } catch (error: any) {
        try {
          if (
            user.token &&
            error.response.status === HttpStatusCode.UNAUTHORIZED
          ) {
            setError({
              message:
                'We are helping to keep your account secure and expired you session, please log in again',
              code: error.response.status,
            });
            setUser(initialUserState);
          } else {
            setError({
              message: readableError(error),
              code: error.response.status,
            });
          }
        } catch {
          setError({
            message:
              'A server error has occured. If the problem persists please contact support',
            code: HttpStatusCode.SERVICE_UNAVAILABLE,
          });
        }
        setLoading(false);
      }
    },
    [method, setUser, url, user.token]
  );

  const clearError = () => {
    setError({ message: '', code: 0 });
  };

  return { data, error, loading, sendRequest, clearError };
};

export const readableError = (error: any) => {
  if (error.response) {
    return error.response?.data?.message
      ? error.response.data.message
      : 'A unknown client error has occured';
  } else if (error.request) {
    return 'A server connection error has occured';
  } else {
    return 'An unknown error has occured';
  }
};
