import {
  Box,
  Button,
  Dialog,
  DialogActions,
  Slide,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { forwardRef, useContext, useEffect } from 'react';
import { useApi } from '../../api/api';
import { MONTHLY_SCREWS, UPGRADE_SCREWS } from '../../constants';
import { SnackbarContext } from '../../contexts/snackbar.context';
import { UserContext } from '../../contexts/user.context';
import { ScrewCounter } from '../ScrewCounter';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...(props as any)} />;
});

export const ScrewsDialog = (props: { open: boolean; onClose: () => void }) => {
  const { open, onClose } = props;
  const { user } = useContext(UserContext);
  const { setMessages } = useContext(SnackbarContext);
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    data: paymentLink,
    error: paymentLinkError,
    sendRequest: getPaymentLink,
  } = useApi<string>('/users/screw-purchase', 'GET');

  useEffect(() => {
    if (!!paymentLink && !paymentLinkError.message) {
      const link = document.createElement('a');
      link.href = paymentLink;
      link.click();
    }
  }, [paymentLink, paymentLinkError]);

  useEffect(() => {
    if (paymentLinkError.message) {
      setMessages((m) => ({
        ...m,
        error: paymentLinkError.message,
      }));
    }
  }, [paymentLinkError, setMessages]);

  return (
    <Dialog
      TransitionComponent={Transition as any}
      fullScreen={smallScreen}
      open={open}
      onClose={onClose}
      style={{
        padding: theme.spacing(3),
        backgroundColor: theme.palette.primary.dark,
      }}
    >
      <Typography
        style={{
          padding: theme.spacing(3),
          backgroundColor: theme.palette.primary.dark,
        }}
        variant="h3"
      >
        Screws
      </Typography>
      <Box
        style={{
          backgroundColor: theme.palette.primary.dark,
          paddingLeft: theme.spacing(3),
          paddingRight: theme.spacing(3),
        }}
      >
        <Typography variant="body1">
          Screws is the currency used on this site to create, upvote, and
          comment on development topics. It's a completely optional feature
          aimed to help devleopment to be closely aligned with what the
          playerbase wants. Any screws purchases will help the development team
          pay their rent and bills ♡♡♡.
        </Typography>
        <Typography variant="body1">
          {`All upgraded accounts will also get ${UPGRADE_SCREWS} screws to start with, plus ${MONTHLY_SCREWS}
          more every month, up to 50. Users with 100, 1,000, and 10,000 life
          time screws will get special badges to show off their support for the
          dev! The topics with the most amount of screws will likely be
          implemented in future updates, so pick your upvotes wisely! You may
          need to wait or refresh the page to see the changes.`}
        </Typography>
        <Typography
          variant="h6"
          style={{ color: theme.palette.secondary.main }}
        >{`current screw count: ${user.screws}`}</Typography>
        <Typography
          variant="h6"
          style={{ color: theme.palette.secondary.main }}
        >{`lifetime screw count: ${user.screwsLifetime}`}</Typography>
        <ScrewCounter count={user.screws} />
      </Box>

      <DialogActions
        style={{
          backgroundColor: theme.palette.primary.dark,
          padding: theme.spacing(2),
        }}
      >
        <Button
          style={{ height: smallScreen ? 90 : 44, width: 120 }}
          onClick={() => {
            getPaymentLink(undefined, { screws: 100 });
          }}
          color="secondary"
          variant="contained"
        >
          Buy 100 $9.99
        </Button>
        <Button
          style={{ height: smallScreen ? 90 : 44, width: 120 }}
          onClick={() => {
            getPaymentLink(undefined, { screws: 1000 });
          }}
          color="secondary"
          variant="contained"
        >
          Buy 1000 (10% off)
        </Button>
        <Button
          style={{ height: smallScreen ? 90 : 44, width: 120 }}
          onClick={() => {
            getPaymentLink(undefined, { screws: 10000 });
          }}
          color="secondary"
          variant="contained"
        >
          Buy 10000 (20% off)
        </Button>
        <Button
          style={{ height: smallScreen ? 90 : 44, width: 100 }}
          onClick={onClose}
          color="secondary"
          variant="contained"
        >
          Dismiss
        </Button>
      </DialogActions>
    </Dialog>
  );
};
