import { Alert, Snackbar } from '@mui/material';
// import Alert from '@mui/lab';
import { Dispatch, SetStateAction, useState } from 'react';
import { createContext } from 'react';

export type SnackbarState = {
  success: string;
  error: string;
  warning: string;
};

const initialState: SnackbarState = {
  success: '',
  error: '',
  warning: '',
};

export const SnackbarContext = createContext({
  messages: initialState,
  setMessages: {} as Dispatch<SetStateAction<SnackbarState>>,
});

export const SnackbarProvider = (props: { children: JSX.Element }) => {
  const [messages, setMessages] = useState(initialState);

  return (
    <SnackbarContext.Provider value={{ messages, setMessages }}>
      <Snackbar
        open={!!messages.error}
        autoHideDuration={5000}
        onClose={() => {
          setMessages({ ...messages, error: '' });
        }}
      >
        <Alert
          variant="filled"
          severity="error"
          onClose={() => {
            setMessages({ ...messages, error: '' });
          }}
        >
          {messages.error}
        </Alert>
      </Snackbar>
      <Snackbar
        open={!!messages.success}
        autoHideDuration={5000}
        onClose={() => {
          setMessages({ ...messages, success: '' });
        }}
      >
        <Alert
          variant="filled"
          severity="success"
          onClose={() => {
            setMessages({ ...messages, success: '' });
          }}
        >
          {messages.success}
        </Alert>
      </Snackbar>
      {props.children}
    </SnackbarContext.Provider>
  );
};
