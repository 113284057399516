import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Link,
  Slide,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/system';
import { forwardRef, useContext, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useApi } from '../../api/api';
import { SnackbarContext } from '../../contexts/snackbar.context';
import { UserContext } from '../../contexts/user.context';
import { TopbarPages } from '../Topbar';
import { TypographyCursor } from '../TypographyCursor';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...(props as any)} />;
});

export const PaymentDialog = (props: {
  open: boolean;
  onClose: () => void;
}) => {
  const { open, onClose } = props;
  const { pageParam } = useParams<{
    pageParam: string | undefined;
    innerPageParam: string | undefined;
  }>();
  const { user } = useContext(UserContext);
  const history = useHistory();
  const { setMessages } = useContext(SnackbarContext);
  const theme = useTheme();

  const {
    data: downloadLink,
    error: downloadLinkError,
    loading: loadingDownloadLink,
    sendRequest: getDownloadLink,
  } = useApi<string>('/download', 'GET');

  const {
    data: paymentLink,
    error: paymentLinkError,
    loading: loadingGetPaymentLink,
    // sendRequest: getPaymentLink,
  } = useApi<string>('/users/upgrade', 'GET');

  useEffect(() => {
    if (!!paymentLink && !paymentLinkError.message) {
      const link = document.createElement('a');
      link.href = paymentLink;
      link.click();
    }
    if (!!downloadLink && !downloadLinkError.message) {
      const link = document.createElement('a');
      link.href = downloadLink;
      link.click();
    }
  }, [downloadLink, downloadLinkError, history, paymentLink, paymentLinkError]);

  useEffect(() => {
    setMessages((m) => ({
      ...m,
      error: (downloadLinkError?.message || paymentLinkError?.message) ?? '',
    }));
  }, [paymentLinkError, downloadLinkError, setMessages]);

  return (
    <Dialog
      fullScreen
      TransitionComponent={Transition as any}
      open={!!open}
      onClose={onClose}
    >
      <Box
        color="primary"
        style={{
          width: '100%',
          backgroundColor: theme.palette.primary.main,
        }}
      >
        <IconButton
          style={{ alignSelf: 'flex-end', margin: theme.spacing(1) }}
          onClick={() => {
            // no dialog close animation but needed for redirect
            if (pageParam === TopbarPages.DOWNLOAD_REDIRECT) history.push(`/`);
            else onClose();
          }}
        >
          <Close />
        </IconButton>
      </Box>
      <Box
        style={{
          padding: theme.spacing(2),
          height: '100%',
          backgroundColor: theme.palette.primary.dark,
        }}
      >
        <DialogTitle>{'Get World of Engineering'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            (This game is in preview state only, only insiders and backers will
            have alpha and beta access. If you have a redeem code you can visit
            the account page on the top right to redeem)
          </DialogContentText>
          {/* <DialogContentText>
            (This is a development preview of World of Engineering, it's
            incredibly incomplete and is being distributed as a proof of concept
            of the frontend and backend capabilities, in addition to the game
            itself. It is not intended for public consumers!)
          </DialogContentText> */}
          {/* <DialogContentText>
            (If you find any issues or have any questions you can post on
            Discord or on the thread on this website)
          </DialogContentText> */}
          <DialogContentText>
            <Link
              style={{
                padding: theme.spacing(2),
                marginBottom: theme.spacing(5),
              }}
              href={'https://discord.gg/VvqvxZ4gJs'}
            >
              discord link
            </Link>
          </DialogContentText>
          {/* <DialogContentText color="error">
            (There is no in game settings yet, here are the default keybindings
            (you can change them in User/AppData/Local/World of
            Engineering/keybinding.txt (You need to run the game at least once
            first). The numbers in keybinging.txt correspond to GLFW key codes):
          </DialogContentText>
          <DialogContentText color="error">
            You'll also want to look at config.txt in the same folder to tweak
            any settings you want, most importantly the gameFps, renderFps,
            shadow, and viewDistance settings.
          </DialogContentText>
          <DialogContentText>
            <Link
              style={{
                padding: theme.spacing(2),
                marginBottom: theme.spacing(5),
              }}
              href="https://www.glfw.org/docs/3.3/group__keys.html"
            >
              View keybinding
            </Link>
          </DialogContentText> */}

          {/* <DialogContentText>
            (You can dm me on Discord for a download key, or if you want to
            donate to development you can purchase the game or screws on this
            website)
          </DialogContentText>
          <DialogContentText color="error">
            (THE GAME IS BUILT FOR WINDOWS ONLY. YOU CAN ONLY DOWNLOAD THE GAME
            UP TO THREE TIMES FOR THE TIME BEING)
          </DialogContentText>
          <Box style={{ marginTop: theme.spacing(20) }} />
          <DialogContentText>
            Upgrade your account to get World of Engineering forever, include
            all future updates.You may need to wait or refresh the page to see
            the download link after purchase.
          </DialogContentText>
          <DialogContentText>
            By clicking the download button you are accept the following end
            user license agreement:
          </DialogContentText>
          <Link href="/EULA.pdf" download>
            View EULA
          </Link>*/}
        </DialogContent>
        <SpacedButton onClick={onClose} color="primary" variant="outlined">
          <TypographyCursor>Close</TypographyCursor>
        </SpacedButton>
        <SpacedButton
          onClick={() => {
            const foo = () => {};
            !user.username
              ? history.push(`/${TopbarPages.SIGNIN}`)
              : user.roles.includes('USER')
              ? getDownloadLink()
              : foo();
            // : getPaymentLink();
          }}
          color="primary"
          variant="outlined"
        >
          {loadingDownloadLink || loadingGetPaymentLink ? (
            <CircularProgress color="secondary" />
          ) : (
            <TypographyCursor>
              {!user.username
                ? 'Sign in'
                : user.roles.includes('USER')
                ? 'Download World of Engineering'
                : 'Account upgrade unavailable until launch'}
            </TypographyCursor>
          )}
        </SpacedButton>
      </Box>
    </Dialog>
  );
};

const SpacedButton = styled(Button)(({ theme }) => ({
  marginRight: theme.spacing(3),
  marginLeft: theme.spacing(3),
  padding: theme.spacing(1),
}));
