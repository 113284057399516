export const entities = [
  { id: 0, name: 'Air' },
  { id: 1, name: 'Dirt' },
  { id: 2, name: 'Grass' },
  { id: 3, name: 'Hematite' },
  { id: 4, name: 'Chert' },
  { id: 5, name: 'Directional Block' },
  { id: 6, name: 'Glass' },
  { id: 7, name: 'Iron Block' },
  { id: 8, name: 'Water' },
  { id: 9, name: 'Ice' },
  { id: 10, name: 'Sand' },
  { id: 11, name: 'Smores' },
  { id: 12, name: 'Infinity Light' },
  { id: 13, name: 'Maple Trunk' },
  { id: 14, name: 'Birch Trunk' },
  { id: 15, name: 'Player' },
  { id: 16, name: 'Grand Piano' },
  { id: 17, name: 'Entity Packet' },
  { id: 18, name: '4D Inventory Multiplex' },
  { id: 19, name: 'Oak Ramp 1x1' },
  { id: 20, name: 'Steel Spikes' },
  { id: 21, name: 'Oak Ramp 1x2' },
  { id: 22, name: 'Oak Planks' },
  { id: 23, name: 'Silly Mirrors' },
];

// To get the names,
// match .*(".*"), ".*".*
// replace $1
// then use sequential number to generate the ids
