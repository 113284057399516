import { Typography } from '@mui/material';

export const TypographyCursor = (props: any) => (
  <Typography
    {...props}
    style={{ ...props.style, cursor: `url('/pointer.png'), auto` }}
  >
    {props.children}
  </Typography>
);
