import { Box, Paper, Tab, Tabs } from '@mui/material';
import { styled } from '@mui/system';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useHistory, useParams } from 'react-router-dom';
import Sound, { ReactSoundProps } from 'react-sound';
import SwipeableViews from 'react-swipeable-views';
import AboutPage from '../components/tabPages/AboutPage';
import DevelopmentPage from '../components/tabPages/development/DevelopmentPage';
import HomePage from '../components/tabPages/HomePage';
import ModsPage from '../components/tabPages/ModsPage';
import UpdatesPage from '../components/tabPages/UpdatesPage';
import WorldsPage from '../components/tabPages/WorldsPage';
import { Topbar } from '../components/Topbar';
import { COOKIE_MUTED } from '../contexts/initialLoader.context';
import { UserContext } from '../contexts/user.context';

export enum MainScreenPages {
  HOME = 'home',
  DEVELOPMENT = 'development',
  FORUM = 'forum',
  WORLDS = 'worlds',
  MODS = 'mods',
  UPDATES = 'updates',
  ABOUT = 'about',
}
export const pagesList = [
  MainScreenPages.HOME,
  MainScreenPages.DEVELOPMENT,
  MainScreenPages.FORUM,
  MainScreenPages.WORLDS,
  MainScreenPages.MODS,
  MainScreenPages.UPDATES,
  MainScreenPages.ABOUT,
];

export const MainScreen = () => {
  const history = useHistory();
  const [page, setPage] = useState(0);
  const { user } = useContext(UserContext);
  const { pageParam } = useParams<{
    pageParam: string | undefined;
    innerPageParam: string | undefined;
  }>();

  const swipeableViewRef = useRef<any>();

  // react to query/history changes
  useEffect(() => {
    const ind = pagesList.findIndex((s) => s === pageParam);
    ind !== -1 && setPage(ind);
  }, [pageParam]);

  const [cookies] = useCookies([COOKIE_MUTED]);

  const [songPlaying, setSongPlaying] =
    useState<ReactSoundProps['playStatus']>('STOPPED');
  const initialPlay = useRef(cookies.muted === 'false');

  // fix react-swipeable-views initial animation
  useEffect(() => {
    swipeableViewRef.current.state.displaySameSlide = false;
  }, []);

  return (
    <MainContainer
      onPointerDown={() => {
        if (initialPlay.current) {
          initialPlay.current = false;
          setSongPlaying('PLAYING');
        }
      }}
    >
      <Topbar setSongPlaying={setSongPlaying} songPlaying={songPlaying} />
      <CenterNavBar>
        <Tabs
          value={page}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          // scrollButtons="on"
          onChange={(event: React.ChangeEvent<{}>, newValue: number) => {
            history.push(`/${pagesList[newValue]}`);
          }}
        >
          <Tab label="Home" />
          <Tab label="Development" />
          <Tab label="Forum" />
          <Tab label="Worlds" />
          <Tab label="Mods" />
          <Tab label="Updates" />
          <Tab label="About" />
        </Tabs>
      </CenterNavBar>

      <Sound
        url="/TitleMusic.wav"
        playStatus={songPlaying}
        onFinishedPlaying={() => {
          setSongPlaying('PLAYING');
        }}
      />
      <SwipeableViews
        ref={swipeableViewRef}
        containerStyle={{
          // class name doesn't work
          width: '100%',
          height: '100%',
        }}
        style={{
          width: '100%',
          height: '100%',
        }}
        index={page}
        onChangeIndex={(index: number) => {
          history.push(`/${pagesList[index]}`);
        }}
      >
        <PageBackground>
          {pageParam === MainScreenPages.HOME && <HomePage />}
          {/* Refresh video when navigating away*/}
        </PageBackground>
        <PageBackground>
          {(pageParam === MainScreenPages.DEVELOPMENT || user.token) && (
            <DevelopmentPage forum={false} />
          )}
          {/* Do not load initially if no user */}
        </PageBackground>
        <PageBackground>
          {(pageParam === MainScreenPages.FORUM || user.token) && (
            <DevelopmentPage forum={true} />
          )}
          {/* Do not load initially if no user */}
        </PageBackground>
        <PageBackground>
          <WorldsPage />
        </PageBackground>
        <PageBackground>
          <ModsPage />
        </PageBackground>
        <PageBackground>
          <UpdatesPage />
        </PageBackground>
        <PageBackground>
          <AboutPage />
        </PageBackground>
      </SwipeableViews>
    </MainContainer>
  );
};

const MainContainer = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: theme.palette.customBackground.main,
  alignItems: 'center',
  flexDirection: 'column',
  display: 'flex',
}));
const CenterNavBar = styled(Paper)(({ theme }) => ({
  maxWidth: '95%',
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  justifySelf: 'center',
  alignSelf: 'center',
}));
const PageBackground = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  backgroundColor: theme.palette.customBackground.main,
}));

export default MainScreen;
