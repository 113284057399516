import {
  Box,
  createTheme,
  responsiveFontSizes,
  StyledEngineProvider,
  ThemeProvider,
  useMediaQuery,
} from '@mui/material';
import { useMemo } from 'react';
import { CookiesProvider } from 'react-cookie';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { InitialLoaderProvider } from './contexts/initialLoader.context';
import { SnackbarProvider } from './contexts/snackbar.context';
import { UserProvider } from './contexts/user.context';
import MainScreen, { MainScreenPages } from './screens/MainScreen';
import { SuccessScreen } from './screens/SuccessScreen';

declare module '@mui/material/styles' {
  interface Palette {
    customBackground: Palette['primary'];
    customDarkBackground: Palette['primary'];
    customWorkspaceBackground: Palette['primary'];
  }
  interface PaletteOptions {
    customBackground: PaletteOptions['primary'];
    customDarkBackground: PaletteOptions['primary'];
    customWorkspaceBackground: PaletteOptions['primary'];
  }
}

export default function App() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const theme = useMemo(() => {
    const actionHoverCursor = {
      styleOverrides: {
        root: {
          '&:hover': {
            cursor: `url('/pointer.png'), auto`,
          },
        },
      },
    };
    const normalCursor = {
      styleOverrides: {
        root: {
          '&:hover': {
            cursor: `url('/mouse.png'), auto`,
          },
        },
      },
    };
    const blinkerCursor = {
      styleOverrides: {
        root: {
          '&:hover': {
            cursor: `url('/blinker.png') 8 10, text`,
          },
        },
      },
    };
    return responsiveFontSizes(
      createTheme({
        components: {
          MuiButtonBase: actionHoverCursor,
          MuiListItem: actionHoverCursor,
          MuiLink: actionHoverCursor,
          MuiAccordionSummary: actionHoverCursor, // not working
          MuiAccordion: actionHoverCursor, // not working
          MuiDialog: normalCursor,
          MuiBackdrop: normalCursor,
          MuiDrawer: normalCursor,
          MuiToolbar: normalCursor,
          MuiInputBase: blinkerCursor,
          MuiTextField: blinkerCursor,
          MuiTypography: blinkerCursor,
        },

        palette: {
          mode: prefersDarkMode ? 'dark' : 'light',
          customBackground: {
            main: prefersDarkMode ? '#0d5272' : '#6ebbdf',
          },
          customWorkspaceBackground: {
            main: prefersDarkMode ? '#0d6791' : '#8ed7f8',
          },
          customDarkBackground: {
            main: prefersDarkMode ? '#102a36' : '#153e52',
          },
          primary: {
            main: '#71c6ee',
          },
          secondary: {
            main: '#ff933b',
          },
          error: {
            main: '#c503c5',
          },
          warning: {
            main: '#eaec68',
          },
          info: {
            main: '#9a53ca',
          },
          success: {
            main: '#59e081',
          },
        },
      })
    );
  }, [prefersDarkMode]);

  return (
    <StyledEngineProvider injectFirst>
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
      />
      <ThemeProvider theme={theme}>
        <Box style={{ cursor: `url('/mouse.png'), auto` }}>
          <SnackbarProvider>
            <CookiesProvider>
              <UserProvider>
                <InitialLoaderProvider>
                  <BrowserRouter>
                    <Switch>
                      <Redirect
                        exact
                        from="/"
                        to={`/${MainScreenPages.HOME}`}
                      />
                      <Route exact path="/success">
                        <SuccessScreen />
                      </Route>
                      <Route path="/:pageParam?/:innerPageParam?">
                        <MainScreen />
                      </Route>
                    </Switch>
                  </BrowserRouter>
                </InitialLoaderProvider>
              </UserProvider>
            </CookiesProvider>
          </SnackbarProvider>
        </Box>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
