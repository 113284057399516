import {
  Accordion,
  AccordionSummary,
  Box,
  Button,
  Card,
  Icon,
  styled,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  AssignmentTurnedIn,
  Computer,
  DoneAll,
  ExpandMore,
} from '@mui/icons-material';
import { format } from 'date-fns';
import { now } from 'lodash';
import { Fragment, useContext, useEffect, useRef, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { useApi } from '../../../api/api';
import {
  REPLY_CREATE_SCREWS,
  TOPIC_BUMP_SCREWS,
  TOPIC_DESCRIPTION_MAX_LENGTH,
  TOPIC_TITLE_MAX_LENGTH,
} from '../../../constants';
import { SnackbarContext } from '../../../contexts/snackbar.context';
import { UserContext } from '../../../contexts/user.context';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { ButtonHeightCircularProgress } from '../../ButtonHeightCircularProgress';
import { DecoratedDisplayName } from '../../DecoratedDisplayName';
import { NumberText } from '../../NumberText';
import { ScrewCounter } from '../../ScrewCounter';
import { TypographyCursor } from '../../TypographyCursor';
import { Topic, UserMap } from './DevelopmentPage';
import { DevelopmentReply } from './DevelopmentReply';

export const DevelopmentTopic = (props: {
  topic: Topic;
  refresh: () => void;
  userMap?: UserMap;
  onClose?: () => void;
  forum: boolean;
}) => {
  const { refresh, userMap, onClose, topic, forum } = props;
  const { user } = useContext(UserContext);
  const scrollingRef = useRef<HTMLDivElement>(null);
  const isAdmin = user.roles.includes('ADMIN');

  const theme = useTheme();
  const { setMessages } = useContext(SnackbarContext);
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [accordionOpen, setAccordionOpen] = useState(false);

  const [textfieldTitle, setTextfieldTitle] = useState('');
  const [textfieldDescription, setTextfieldDescription] = useState('');

  const [state, setState] = useState<'new' | 'amend' | 'overwrite' | 'default'>(
    topic.title === '' ? 'new' : 'default'
  );
  const [newReply, setNewReply] = useState(false);

  //#region api
  const {
    data: createTopicResponse,
    loading: loadingCreateTopic,
    error: errorCreateTopic,
    sendRequest: createTopic,
  } = useApi('topic', 'POST');

  const {
    data: bumpTopicResponse,
    loading: loadingBumpTopic,
    error: errorBumpTopic,
    sendRequest: bumpTopic,
  } = useApi('topic/bump', 'POST');

  const {
    data: overwriteTopicResponse,
    loading: loadingOverwriteTopic,
    error: errorOverwriteTopic,
    sendRequest: overwriteTopic,
  } = useApi('topic', 'PATCH');

  const {
    data: amendTopicResponse,
    loading: loadingAmendTopic,
    error: errorAmendTopic,
    sendRequest: amendTopic,
  } = useApi('topic/amend', 'PATCH');

  const {
    data: deleteTopicResponse,
    loading: loadingDeleteTopic,
    error: errorDeleteTopic,
    sendRequest: deleteTopic,
  } = useApi('topic', 'DELETE');

  //#endregion

  //#region set messages
  useEffect(() => {
    if (errorCreateTopic.message) {
      setMessages((m) => ({
        ...m,
        error: errorCreateTopic.message,
      }));
    } else if (createTopicResponse) {
      console.log('okay');
      setMessages((m) => ({
        ...m,
        success: 'Topic created',
      }));
      refresh();
      onClose && onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createTopicResponse, errorCreateTopic, setMessages]);

  useEffect(() => {
    if (deleteTopicResponse && !errorDeleteTopic.message) {
      setMessages((m) => ({ ...m, success: 'Success' }));
      refresh();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteTopicResponse, errorDeleteTopic, setMessages]);

  useEffect(() => {
    if (amendTopicResponse && !errorAmendTopic.message) {
      setMessages((m) => ({ ...m, success: 'Success' }));
      setState('default');
      refresh();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amendTopicResponse, errorAmendTopic, setMessages]);

  useEffect(() => {
    if (overwriteTopicResponse && !errorOverwriteTopic.message) {
      setMessages((m) => ({ ...m, success: 'Success' }));
      setState('default');
      refresh();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [overwriteTopicResponse, errorOverwriteTopic, setMessages]);

  useEffect(() => {
    if (bumpTopicResponse && !errorBumpTopic.message) {
      setMessages((m) => ({ ...m, success: 'Success' }));
      refresh();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bumpTopicResponse, errorBumpTopic, setMessages]);

  //#endregion

  const titleRef = useRef<HTMLSpanElement>(null);
  const editAmendDividerText = `\n\n---\n\n#### Edit ${format(
    now(),
    'Pp'
  )}:\n\n`;
  const [width] = useWindowSize();
  const [showTitleTooptip, setShowTitleTooltip] = useState(false);

  useEffect(() => {
    if (
      (titleRef.current?.offsetWidth ?? 0) !==
      (titleRef.current?.scrollWidth ?? 0)
    )
      setShowTitleTooltip(true);
    else setShowTitleTooltip(false);
  }, [width]);

  const ResponsiveContainer = smallScreen
    ? VerticalContainer
    : HorizontalContainer;

  return (
    <Card
      style={{
        marginBottom: 50,
        width: '90%',
        backgroundColor: theme.palette.primary.main,
        padding: theme.spacing(2),
      }}
    >
      <ResponsiveContainer>
        <Box style={{ minWidth: smallScreen ? '100%' : '60%' }}>
          <Tooltip title={showTitleTooptip ? topic.title : ''}>
            {state === 'overwrite' || state === 'new' ? (
              <TextField
                autoFocus={state === 'overwrite'}
                placeholder="Title"
                defaultValue={topic.title}
                value={textfieldTitle}
                onChange={(e) => setTextfieldTitle(e.target.value)}
                fullWidth
                style={{ color: theme.palette.secondary.contrastText }}
                error={
                  !textfieldTitle ||
                  textfieldTitle.length >= TOPIC_TITLE_MAX_LENGTH
                }
                helperText={`Must be unique and not empty. You cannot change this later! limit: (${textfieldTitle.length}/${TOPIC_TITLE_MAX_LENGTH})`}
                inputProps={{ maxLength: TOPIC_TITLE_MAX_LENGTH }}
              />
            ) : (
              <Typography
                ref={titleRef}
                variant="h4"
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  color: theme.palette.secondary.contrastText,
                }}
              >
                {topic.title}
              </Typography>
            )}
          </Tooltip>
          {topic.state === 'committed' && (
            <Tooltip title="will be worked on">
              <Icon color="secondary" style={{ marginLeft: theme.spacing(1) }}>
                <AssignmentTurnedIn />
              </Icon>
            </Tooltip>
          )}
          {topic.state === 'done' && (
            <Tooltip title="Finished">
              <Icon color="secondary" style={{ marginLeft: theme.spacing(1) }}>
                <DoneAll />
              </Icon>
            </Tooltip>
          )}
          {topic.state === 'inProgress' && (
            <Tooltip title="In Progress">
              <Icon color="secondary" style={{ marginLeft: theme.spacing(1) }}>
                <Computer />
              </Icon>
            </Tooltip>
          )}
        </Box>
        <Box style={{ maxWidth: smallScreen ? '100%' : '40%' }}>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <NumberText
              amount={topic.screws}
              prefix="Screws: "
              style={{ color: theme.palette.secondary.contrastText }}
            />
            {state === 'default' && (
              <Button
                disabled={
                  !forum &&
                  (loadingBumpTopic || user.screws < TOPIC_BUMP_SCREWS)
                }
                style={{ marginLeft: theme.spacing(2) }}
                color="secondary"
                variant="contained"
                onClick={() => {
                  bumpTopic({
                    title: topic.title,
                    forum,
                  });
                }}
              >
                {loadingBumpTopic ? (
                  <ButtonHeightCircularProgress />
                ) : forum ? (
                  'Bump'
                ) : (
                  `Bump (${TOPIC_BUMP_SCREWS} screw${
                    TOPIC_BUMP_SCREWS === 1 ? '' : 's'
                  })`
                )}
              </Button>
            )}
            {!(!isAdmin && topic.creatorName !== user.displayName) && (
              <Button
                style={{
                  marginLeft: theme.spacing(2),
                }}
                color="secondary"
                variant="contained"
                onClick={() => {
                  if (state === 'overwrite')
                    overwriteTopic({
                      title: topic.title,
                      updateTitle: textfieldTitle,
                      updateDescription: textfieldDescription,
                      forum,
                    });
                  else if (state === 'amend')
                    amendTopic({
                      title: topic.title,
                      updateDescription: textfieldDescription,
                      forum,
                    });
                  else if (state === 'new')
                    createTopic({
                      title: textfieldTitle,
                      description: textfieldDescription,
                      forum,
                    });
                  else if (state === 'default') {
                    if (isAdmin) {
                      setState('overwrite');
                      setTextfieldTitle(topic.title);
                      setTextfieldDescription(topic.description);
                    } else {
                      setState('amend');
                      setTextfieldTitle('');
                      setTextfieldDescription('');
                    }
                  }
                }}
                disabled={
                  (state === 'amend' && !textfieldDescription) ||
                  ((state === 'overwrite' || state === 'new') &&
                    (!textfieldTitle || !textfieldDescription))
                }
              >
                {loadingCreateTopic ||
                loadingOverwriteTopic ||
                loadingAmendTopic ? (
                  <ButtonHeightCircularProgress />
                ) : state === 'default' ? (
                  isAdmin ? (
                    'Edit'
                  ) : (
                    'Amend'
                  )
                ) : (
                  'save'
                )}
              </Button>
            )}
            {state !== 'default' && (
              <Button
                style={{
                  marginLeft: theme.spacing(2),
                }}
                color="secondary"
                variant="contained"
                onClick={() => {
                  if (state === 'new') onClose && onClose();
                  else {
                    setState('default');
                    setTextfieldTitle('');
                    setTextfieldDescription('');
                  }
                }}
              >
                Cancel
              </Button>
            )}
            {isAdmin && topic.title !== '' && (
              <Button
                disabled={loadingDeleteTopic}
                style={{
                  marginLeft: theme.spacing(2),
                  backgroundColor: theme.palette.error.main,
                }}
                variant="contained"
                onClick={() => {
                  if (
                    window.confirm(
                      'REALLY REALLY DELETE THIS TOPIC? ALL REPLIES ASSOCIATED WITH THIS TOPIC WILL ALSO BE DELETED!!! THIS IS IRREVERSIBLE!!!'
                    )
                  )
                    if (window.confirm('ARE YOU SURE?!?!?!'))
                      if (window.confirm('ARE YOU VERY VERY SURE?!?!?!'))
                        deleteTopic({ title: topic.title, forum });
                }}
              >
                {loadingDeleteTopic ? (
                  <ButtonHeightCircularProgress />
                ) : (
                  'DELETE'
                )}
              </Button>
            )}
          </Box>
          <ScrewCounter count={topic.screws} />
        </Box>
      </ResponsiveContainer>
      <DecoratedDisplayName displayName={topic.creatorName} userMap={userMap} />

      {state !== 'default' && (
        <Fragment>
          <Description>
            <TextField
              autoFocus={state === 'amend'}
              placeholder={state === 'amend' ? 'amend' : 'Description'}
              defaultValue={topic.description}
              error={
                !textfieldDescription ||
                textfieldDescription.length >= TOPIC_DESCRIPTION_MAX_LENGTH
              }
              helperText={`Must not be empty. You can amend this later. Markdown formatted (use 2 enter keys per new line). limit: (${
                state === 'amend'
                  ? textfieldDescription.length +
                    editAmendDividerText.length +
                    3 + // in case date becomes longer when submitted
                    topic.description.length
                  : textfieldDescription.length
              }/${TOPIC_DESCRIPTION_MAX_LENGTH})`}
              inputProps={{ maxLength: TOPIC_DESCRIPTION_MAX_LENGTH }}
              value={textfieldDescription}
              onChange={(e) => setTextfieldDescription(e.target.value)}
              multiline
              fullWidth
            />
          </Description>
          <Typography
            variant="h4"
            style={{ color: theme.palette.secondary.contrastText }}
          >
            Preview
          </Typography>
        </Fragment>
      )}
      <Description>
        <ReactMarkdown>
          {state === 'amend'
            ? topic.description + editAmendDividerText + textfieldDescription
            : state === 'default'
            ? topic.description
            : textfieldDescription}
        </ReactMarkdown>
      </Description>

      {state !== 'new' && (
        <Accordion
          style={{ backgroundColor: theme.palette.primary.dark }}
          expanded={accordionOpen}
          onChange={(e, expanded) => setAccordionOpen(expanded)}
        >
          <AccordionSummary
            expandIcon={<ExpandMore />}
            style={{ cursor: `url('/pointer.png'), auto` }}
          >
            <TypographyCursor>
              {accordionOpen ? 'Hide Replies' : 'Show Replies'}
            </TypographyCursor>
          </AccordionSummary>
          {state === 'default' && (
            <Button
              disabled={!forum && user.screws < REPLY_CREATE_SCREWS}
              variant="contained"
              color="secondary"
              onClick={() => {
                if (newReply) setNewReply(false);
                else setNewReply(true);
              }}
              style={{
                margin: theme.spacing(2),
              }}
            >
              {newReply
                ? 'Cancel'
                : forum
                ? 'Reply'
                : `Reply (${REPLY_CREATE_SCREWS} screw${
                    REPLY_CREATE_SCREWS === 1 ? '' : 's'
                  })`}
            </Button>
          )}
          <div ref={scrollingRef} style={{ height: '100%' }}>
            <Box
              style={{
                backgroundColor: theme.palette.primary.main,
                borderColor: theme.palette.primary.dark,
                borderWidth: 4,
                margin: theme.spacing(2),
              }}
            >
              {newReply && (
                <DevelopmentReply
                  forum={forum}
                  topic={topic}
                  reply={{
                    creatorName: user.displayName,
                    message: '',
                    screws: REPLY_CREATE_SCREWS,
                    thread: [],
                  }}
                  userMap={userMap}
                  refresh={refresh}
                  onClose={() => {
                    setNewReply(false);
                  }}
                />
              )}
              {topic?.replies
                ?.sort((a, b) => {
                  if (
                    a.creatorName === user.displayName &&
                    b.creatorName !== user.displayName
                  )
                    return -1;
                  else if (
                    b.creatorName === user.displayName &&
                    a.creatorName !== user.displayName
                  )
                    return 1;
                  if (b.screws - a.screws < 0) return -1;
                  else if (b.screws - a.screws > 0) return 1;
                  if (b.message < a.message) return -1;
                  else if (b.message > a.message) return 1;
                  return 0;
                })
                ?.map((reply, replyIndex) => (
                  <DevelopmentReply
                    key={replyIndex}
                    topic={topic}
                    reply={reply}
                    userMap={userMap}
                    refresh={refresh}
                    forum={forum}
                  />
                ))}
            </Box>
          </div>
        </Accordion>
      )}
    </Card>
  );
};

const HorizontalContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const VerticalContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}));

const Description = styled(Box)(({ theme }) => ({
  cursor: `url('/blinker.png') 8 10, text`,
  padding: theme.spacing(1),
  margin: theme.spacing(1),
  maxHeight: 500,
  resize: 'both',
  overflow: 'auto',
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.primary.light,
  fontFamily: 'Roboto, sans-serif',
}));
